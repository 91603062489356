export const ProductListMetaData = [
  { field: "productId", headerName: "ID", width: 170 },
  { field: "productName", headerName: "Product Name", width: 150 },
  { field: "qty", headerName: "Quantity", width: 100 },
  { field: "productLink", headerName: "Product Link", width: 400 },
];

export const UserListMetaData = [
  { field: "name", headerName: "Name", width: 130 },
  { field: "phone", headerName: "Phone Number", width: 130 },
  { field: "email", headerName: "Email", width: 130 },
  { field: "cr_date", headerName: "Created Date", width: 130 },
];

export const ProductInitData = {
  colorOptions: [],
  productMRP: 0,
  displayPrice: 0,
  images: "",
  productName: "",
  productDescription: "",
  sizes: [],
  designDetails: [],
  materialAndCare: [],
};

export const Sizes = [
  { name: "S", id: 1 },
  { name: "XS", id: 2 },
  { name: "L", id: 3 },
  { name: "M", id: 4 },
  { name: "XL", id: 5 },
  { name: "XXL", id: 6 },
  { name: "XXXL", id: 7 },
  { name: "Free Size", id: 7 },
  { name: "Semi Stitched", id: 8 },
  { name: "Un Stitched", id: 9 },
];

export const Genders = [
  { name: "Woman", id: 1 },
  { name: "Man", id: 2 },
  { name: "Transgender", id: 3 },
  { name: "Prefer not to respond", id: 4 },
];

export const OrderStatus = {
  1: "cart",
  2: "processing",
  3: "processing completed",
  4: "dispatched",
  5: "on the way",
  6: "out for delivery",
  7: "delivered",
  8: "returned",
  99: "cancelled",
};

export const PaymentMod = {
  1: "Cash on Delivery",
  2: "UPI",
  3: "Credit/debit card",
};

export const StatusType = [
  { value: "-1", label: "All" },
  { value: "99", label: "cancelled" },
  { value: "98", label: "cancelled by seller" },
  { value: "2", label: "processing" },
  { value: "3", label: "processing completed" },
  { value: "4", label: "dispatched" },
  { value: "5", label: "on the way" },
  { value: "6", label: "out for delivery" },
  { value: "7", label: "delivered" },
  { value: "8", label: "return" },
  { value: "9", label: "return completed" },
];

export const AccountDetailsItemMap = {
  Name: 101,
  Gender: 102,
  "Alternate Phone": 103,
};

export const AccountMenu = [
  { id: 1, label: "Profile" },
  { id: 2, label: "Delivery Address" },
  { id: 3, label: "My Orders" },
  { id: 5, label: "Change Password" },
];
export const DashboardMenus = [
  { id: 17, label: "Dashboard" },
  { id: 11, label: "Create Category" },
  { id: 12, label: "Add Products" },
  { id: 13, label: "All Orders" },
  { id: 14, label: "View Product list" },
  { id: 15, label: "Create User" },
  { id: 16, label: "List Users" },
];
export const CategoryMetaData = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "parent", headerName: "Parent Category", width: 130 },
  { field: "label", headerName: "Category Name", width: 130 },
];

export const indiaStates = [
  "Arunachal Pradesh",
  "Andra Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];

export const InputWidth = 480;
