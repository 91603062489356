import React from "react";
import moment from "moment";
import ProductCard from "../ProductCard/ProductCard";

function OrderCard({ order, date = 0, isAdmin, update }) {
  console.log("orderCard::date, order", order);
  let total = 0;
  order.forEach(({ price, quantity }) => {
    total += Number(price) * Number(quantity);
  });
  return (
    <div className="orderCardWrapper">
      <div className="card width-100">
        <div className="cardHead">
          <div className="orderDate">
            <div>ORDER PLACED</div>
            <div>{moment(Number(date)).format("MMMM Do YYYY")}</div>
          </div>
          <div>
            <div>TOTAL</div>
            <div>{total}</div>
          </div>
        </div>
        {order?.map((product) => (
          <ProductCard update={update} isAdmin={isAdmin} product={product} />
        ))}
      </div>
    </div>
  );
}

export default OrderCard;
