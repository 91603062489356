import React, { useState } from "react";
import { Grid, Button, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useApiCall } from "../../../hooks/useApiCall";
import { endpoints } from "../../../services/apis";
import { ProductListMetaData, InputWidth } from "../../../helpers/constants";
import Loader from "../../molecules/Loader/Loader";
import { commonPost } from "../../../services/common";
import CustomDialog from "../../molecules/CustomDialog/CustomDialog";

function ProductList() {
  const [viewModal, setViewModal] = useState(false);
  const [field, setField] = useState("");
  const [newVal, setNewVal] = useState("");
  const [data, loading, , update] = useApiCall({ url: endpoints.listProducts });
  const [selectedRows, setSelectedRows] = useState([]);

  const disabeldBtn = selectedRows.length === 0;

  const getData = () => {
    return data.map((d) => ({ ...d, id: d._id }));
  };

  const handleDelete = async () => {
    if (disabeldBtn) return;
    await commonPost(endpoints.deleteProducts, { ids: selectedRows });
    await update();
  };

  const handleCellClick = (params) => {
    console.log('params::', params)
    const {field, value} = params;
    if (field !== "productLink") return;
    window.open(value, "_blank")
  }

  const preCheck = () => {
    if (disabeldBtn) return;
    const selected = selectedRows[0];
    const item = data.find(({ _id }) => selected === _id);
    setViewModal(true);
    return item;
  };

  const handleUpdate = async () => {
    const item = preCheck();
    setNewVal(item.qty);
    setField("newStockCount");
  };
  const handleUpdateYoutube = async () => {
    const item = preCheck();
    setNewVal(item.youtube);
    setField("youtube");
  };

  const handleSubmit = async () => {
    const { updateYoutubeLink, updateQuantity } = endpoints;
    const url = field === "youtube" ? updateYoutubeLink : updateQuantity;
    const payload = {
      _id: selectedRows[0],
      [field]: newVal,
    };
    await commonPost(url, payload);
    await update();
    setViewModal(false);
  };

  return (
    <div className="productListWrapper">
      <div className="btns">
        <Button
          onClick={handleDelete}
          disabled={disabeldBtn}
          variant="contained"
        >
          Delete
        </Button>
        <Button
          onClick={handleUpdate}
          disabled={disabeldBtn}
          variant="contained"
        >
          Update Quantity
        </Button>
        <Button
          onClick={handleUpdateYoutube}
          disabled={disabeldBtn}
          variant="contained"
        >
          Update Youtube
        </Button>
      </div>
      <Grid container spacing={2}>
        <Loader loading={loading} />
        <Grid item xs={12}>
          {data && Array.isArray(data) && data.length > 0 && (
            <div style={{ height: InputWidth, width: "100%" }}>
              <DataGrid
                rows={getData()}
                columns={ProductListMetaData}
                pageSize={6}
                rowsPerPageOptions={[100]}
                checkboxSelection
                onCellClick={handleCellClick}
                onSelectionModelChange={(ids) => setSelectedRows(ids)}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <CustomDialog open={viewModal} setOpen={setViewModal}>
        <div className="editQty">
          <div className="input-item">
            <TextField
              onChange={(e) => setNewVal(e.target.value)}
              sx={{ width: "100%" }}
              value={newVal}
              id="product-fields-id"
              name="fields"
              label={field}
              variant="outlined"
            />
          </div>
          <Button onClick={handleSubmit} variant="contained">
            Update
          </Button>
        </div>
      </CustomDialog>
    </div>
  );
}

export default ProductList;
