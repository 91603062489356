import React, { useState, useEffect } from "react";
import { TextField, Button, Autocomplete } from "@mui/material";
import { indiaStates } from "../../../helpers/constants";
import "./NewAddress.css";
import { commonPost } from "../../../services/common";
import { endpoints } from "../../../services/apis";

function NewAddress({ close, data, getNew }) {
  const [addressInput, setAddressInput] = useState({
    name: "",
    phone: "",
    pin: "",
    address1: "",
    address2: "",
    landMark: "",
    town: "",
    state: "",
    isDefault: true,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddressInput({ ...addressInput, [name]: value });
  };
  const handleAutoComplete = (e, value) => {
    setAddressInput({ ...addressInput, state: value });
  };
  const handleSubmit = async () => {
    await commonPost(endpoints.addAddress, addressInput);
    getNew();
    close(false);
  };
  useEffect(() => {
    if (data && Object.keys(data).length > 0) setAddressInput(data);
  }, [data]);
  return (
    <div className="new-address-wrapper">
      <div className="input">
        <TextField
          required
          value={addressInput.name}
          onChange={handleChange}
          name="name"
          fullWidth
          id="new-address-required1"
          label="First and Last name"
          placeholder="Full Name"
        />
      </div>
      <div className="input">
        <TextField
          value={addressInput.phone}
          onChange={handleChange}
          name="phone"
          required
          fullWidth
          id="new-address-required2"
          label="Mobile number"
        />
      </div>
      <div className="input">
        <TextField
          required
          fullWidth
          value={addressInput.pin}
          onChange={handleChange}
          name="pin"
          id="new-address-required3"
          label="Pincode"
          placeholder="6 digit [0-9] PIN code"
        />
      </div>
      <div className="input">
        <TextField
          required
          value={addressInput.address1}
          onChange={handleChange}
          name="address1"
          fullWidth
          id="new-address-required4"
          label="Flat, House no., Building, Company, Apartment"
        />
      </div>
      <div className="input">
        <TextField
          required
          value={addressInput.address2}
          onChange={handleChange}
          name="address2"
          fullWidth
          id="new-address-required5"
          label="Area, Street, Sector, Village"
        />
      </div>
      <div className="input">
        <TextField
          required
          value={addressInput.landMark}
          onChange={handleChange}
          name="landMark"
          fullWidth
          id="new-address-required6"
          label="Landmark"
          placeholder="E.g. near apollo hospital"
        />
      </div>
      <div className="input flex">
        <TextField
          value={addressInput.town}
          onChange={handleChange}
          name="town"
          required
          id="new-address-required7"
          label="Town/City"
        />
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          value={addressInput.state}
          onChange={handleAutoComplete}
          options={indiaStates}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Choose State" />
          )}
        />
      </div>
      <div className="center width-100">
        <Button onClick={handleSubmit} variant="contained" color="success">
          Submit
        </Button>
      </div>
    </div>
  );
}

export default NewAddress;
