import React, { useEffect, useState } from "react";
import { TextField, Button, Link } from "@mui/material";
import "./LoginCard.css";
import {
  forgotPassword,
  verifyOtp,
} from "../../../services/authenticationService";

function ForgotPassword({ changePage }) {
  const [showOtp, setShowOtp] = useState(false);
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [buttonLabel, setButtonLabel] = useState("Submit");

  const handleButtonClick = async () => {
    try {
      let un = phone;
      const isCodeAvailable = phone.indexOf("+91") === -1;
      if (phone && !isNaN(phone) && isCodeAvailable) {
        un = "+91" + phone;
      }
      const payload = { phone: un };
      if (showOtp) {
        payload.otp = otp;
        await verifyOtp(payload);
        changePage(4);
      } else {
        await forgotPassword(payload);
        setShowOtp(true);
      }
    } catch (e) {
      console.log("handleButtonCLick::e", e);
      return;
    }
  };

  useEffect(() => {
    const text = showOtp ? "Verify" : "Submit";
    setButtonLabel(text);
  }, [showOtp]);
  return (
    <div className="loginWrapper card-shadow">
      <div className="title">
        <h2>Forgot Password</h2>
      </div>
      <div className="input">
        <TextField
          required
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          fullWidth
          id="outlined-required"
          label="Phone"
          placeholder="Enter 10 digit phone"
        />
      </div>
      {showOtp && (
        <div className="input">
          <TextField
            required
            fullWidth
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            id="outlined-required"
            label="OTP"
            placeholder="Enter the OTP"
          />
        </div>
      )}
      <div className="btn center width-100">
        <Button variant="contained" onClick={handleButtonClick} color="success">
          {buttonLabel}
        </Button>
      </div>
      <div className="loginFooter ">
        <Link onClick={() => changePage(2)} underline="hover">
          <div className="helper-text center">
            Don't have an account! Register Here
          </div>
        </Link>
        <Link onClick={() => changePage(1)} underline="hover">
          <div className="helper-text center">Back to Login</div>
        </Link>
      </div>
    </div>
  );
}

export default ForgotPassword;
