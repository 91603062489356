import React from "react";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  ListItemText,
  OutlinedInput,
  Checkbox,
} from "@mui/material";
import { InputWidth } from "../../../helpers/constants";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function SingleDropDown({ label, selected, id, change, data }) {
  const handleChange = (e) => {
    const { value } = e.target;
    change(value);
  };
  return (
    <FormControl sx={{ m: 1, margin: 0, width: "100%" }}>
      <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-helper-label"
        id={"simple-select-" + id}
        value={selected}
        label={label}
        onChange={handleChange}
        MenuProps={MenuProps}
      >
        {data?.map((item,idx) => (
          <MenuItem key={idx} value={item}>
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SingleDropDown;
