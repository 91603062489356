import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Link } from "@mui/material";
import "./LoginCard.css";
import { login } from "../../../services/authenticationService";
import { ProductContext } from "../../../Contexts/ProductContext";

function LoginCard({ changePage }) {
  const [userInput, setUserInput] = useState({ username: "", password: "" });
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setUserInput({ ...userInput, [name]: value });
  };
  const { setUser, getCart } = useContext(ProductContext);
  const handleLogin = async () => {
    const inputs = { ...userInput };
    const { username } = inputs;
    const isCodeAvailable = username.indexOf("+91") === -1;
    if (username && !isNaN(username) && isCodeAvailable) {
      inputs.username = "+91" + username;
    }
    const resp = await login(inputs);
    if (resp?.token) {
      setUser(resp?.user);
      getCart();
      navigate("/user-home");
    } else {
      alert("invalid credentials");
    }
  };
  return (
    <div className="loginWrapper card-shadow">
      <div className="title">
        <h2>User Login</h2>
      </div>
      <div className="input">
        <TextField
          required
          fullWidth
          name="username"
          value={userInput.username}
          onChange={handleInputChange}
          id="outlined-required"
          label="Username"
          placeholder="Email / phone"
        />
      </div>
      <div className="input">
        <TextField
          required
          fullWidth
          name="password"
          onChange={handleInputChange}
          value={userInput.password}
          type="password"
          id="outlined-required"
          label="Password"
          placeholder="Enter Password"
        />
      </div>
      <div className="btn center width-100">
        <Button onClick={handleLogin} variant="contained" color="success">
          Submit
        </Button>
      </div>
      <div className="loginFooter ">
        <Link onClick={() => changePage(3)} underline="hover">
          <div className="helper-text center">Forgot Password?</div>
        </Link>
        <Link onClick={() => changePage(2)} underline="hover">
          <div className="helper-text center">
            Don't have an account! Register Here
          </div>
        </Link>
      </div>
    </div>
  );
}

export default LoginCard;
