import React, { useContext } from "react";
import { Button } from "@mui/material";
import { ProductContext } from "../../../Contexts/ProductContext";
import { commonGet } from "../../../services/common";
import { endpoints } from "../../../services/apis";
import Subtotal from "../../molecules/Subtotal";

function Cart() {
  const { cart, getCart } = useContext(ProductContext);
  const handleRemove = async (_id) => {
    console.log("handleRemove::id", _id);
    await commonGet(endpoints.removeCartItem, { ":_id": _id });
    getCart();
  };
  return (
    <div>
      <div className="title pad-20">Cart</div>
      <Subtotal url="/buy/addressselect/handlers" />
      <div className="cartWrapper">
        {cart.length === 0 && <p className="pad-l-20">Cart is empty</p>}
        {cart.map((item) => (
          <div key={item.productId} className="cartItem">
            <div className="imageSection">
              <img src={item.color} alt={item.productName} />
            </div>
            <div className="description">
              <div className="descTitle bold">{item.productName}</div>
              <div className="price">
                <span>Price:{" ₹" + item.price}</span>
              </div>
              <div className="row">Quantity Selected: {item.quantity}</div>
              <div className="row">Size Selected: {item.size}</div>
              <Button
                onClick={(e) => handleRemove(item._id)}
                variant="outlined"
                size="small"
                color="error"
                sx={{ maxWidth: 300 }}
              >
                Remove
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Cart;
