import { endpoints } from "../services/apis";
import { commonPostFormData } from "../services/common";

export const isArrayValid = (arr) => {
  if (arr && Array.isArray(arr) && arr.length > 0) return true;
  return false;
};

export const uploadImages = async (files) => {
  try {
    const promises = [];
    files.forEach((file) => {
      const body = new FormData();
      body.append(`file`, file);
      const result = commonPostFormData(endpoints.uploadImages, body);
      promises.push(result);
    });
    return await Promise.all(promises);
  } catch (e) {
    console.log("something went wrong in upload images");
  }
};

// export const GetAddress = (addresses, id) => {
//   if (!addresses || addresses.length === 0) return "";
//   let address = addresses.find({ addressId }=>addressId===id);
//   if (!address) address = addresses[0];
//   const {name,pin,address1, address2, landMark,town,state} address1;
//   let addStr =

// }
