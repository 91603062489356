import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      {/* <div className="footer__container">
        <div className="footer__contact">
          <p>Phone: [Your contact number]</p>
          <p>Email: [Your email address]</p>
          <p>Address: [Your physical address]</p>
        </div>
        <div className="footer__social">
          <a href="[Your Facebook URL]">Facebook</a>
          <a href="[Your Instagram URL]">Instagram</a>
        </div>
      </div> */}
      <div className="center">
        <p>&copy; 2023 AyraasFashion. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
