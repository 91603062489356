import React from "react";
import { Button, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CustomSnackbar = ({ values, setValues }) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setValues({ show: false, msg: "" });
  };

  const action = (
    <IconButton size="small" aria-label="close" onClick={handleClose}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  return (
    <Snackbar
      open={values?.show}
      autoHideDuration={4000}
      onClose={handleClose}
      message={values?.msg}
      action={action}
    />
  );
};

export default CustomSnackbar;
