import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { ProductContext } from '../../Contexts/ProductContext';
import { Button } from '@mui/material';

function Subtotal({url, click}) {
  const [subTotal, setSubTotal] = useState(0);
  const { cart } = useContext(ProductContext);
  const navigate = useNavigate();
  const getSubTotal = () => {
    let total = 0;
    cart.forEach(({ price, quantity }) => {
      total += Number(price) * Number(quantity);
    });
    setSubTotal(total);
  };
  useEffect(() => {
    getSubTotal();
  }, [cart]);
  const handleProceed = () => {
    if (!cart || cart.length === 0) return;
    if (click && typeof click === "function") {
        click();
        return;
    }
    navigate(url);
  };
  return (
    <div className="cart-overview">
        <div className="row">
          Subtotal<span className="subTotal">{"₹" + subTotal}</span>
        </div>
        <Button
          disabled={!cart || cart.length === 0}
          onClick={handleProceed}
          sx={{
            backgroundColor: "#373f50",
            textTransform: "none",
            maxWidth: 300,
          }}
          variant="contained"
          fullWidth
        >
          Proceed to Buy ({cart.length} item{cart.length > 1 ? "s" : ""})
        </Button>
      </div>
  )
}

export default Subtotal