import React from "react";
import OrderList from "../OrderList/OrderList";

function Orders() {
  return (
    <div className="order-wrapper">
      <OrderList isAdmin={true} />
    </div>
  );
}

export default Orders;
