import axios from "axios";
import { getBaseUrl } from "./apis";

const baseUrl = getBaseUrl();
// const baseUrl = "http://localhost:3400";

// Set config defaults when creating the instance
// const instance = axios.create({
//   baseURL: 'https://api.example.com'
// });

// const getToken = () => {
//     const token = localStorage.getItem('token');
//     if (token && token !== "") {
//         instance.defaults.headers.common['Authorization'] = 'bearer '+token;
//     }
// }

export const commonGet = async (url, payload) => {
  try {
    if (payload && Object.keys(payload).length > 0) {
      Object.keys(payload).forEach((key) => {
        url = url.replace(key, payload[key]);
      });
    }
    const token = localStorage.getItem("token");
    let resp;
    if (token) {
      resp = await axios.get(baseUrl + url, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } else resp = await axios.get(baseUrl + url);
    return [resp?.status, resp?.data.data];
  } catch (err) {
    console.log("something went wrong::commonGet", err.message);
    return [500, {}];
  }
};

export const commonPost = async (url, payload) => {
  try {
    const token = localStorage.getItem("token");
    let resp;
    if (token) {
      resp = await axios.post(baseUrl + url, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } else resp = await axios.post(baseUrl + url, payload);
    return [resp?.status, resp?.data.data, resp?.data?.message];
  } catch (err) {
    console.log("something went wrong::commonGet", err.message);
    return [500, {}];
  }
};

export const commonPostFormData = async (url, payload) => {
  try {
    const token = localStorage.getItem("token") || "no token";
    let resp = await axios.post(baseUrl + url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    if (resp.status === 200 || resp.status === "200") return resp?.data.data;
  } catch (err) {
    console.log("something went wrong::commonGet", err.message);
    return [500, {}];
  }
};
