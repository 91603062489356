import React, { useState } from "react";
import { Grid, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useApiCall } from "../../hooks/useApiCall";
import { endpoints } from "../../services/apis";
import { InputWidth, UserListMetaData } from "../../helpers/constants";
import Loader from "../molecules/Loader/Loader";
import { commonPost } from "../../services/common";
import moment from "moment";

function ViewUserList() {
  const [data, loading, , update] = useApiCall({ url: endpoints.userList });
  const [selectedRows, setSelectedRows] = useState([]);

  const getData = () => {
    return data
      .map((d) => ({
        ...d,
        id: d._id,
        cr_date: moment(d?.cr_date).format("Do MMM[,] YYYY"),
      }))
      .reverse();
  };

  const handleDelete = async () => {
    if (!selectedRows || selectedRows.length === 0) return;
    await commonPost(endpoints.deleteUser, { ids: selectedRows });
    await update();
  };

  return (
    <div className="productListWrapper">
      <div className="btns">
        <Button
          onClick={handleDelete}
          disabled={selectedRows.length === 0}
          variant="contained"
        >
          Delete
        </Button>
      </div>
      <Grid container spacing={2}>
        <Loader loading={loading} />
        {!loading && (
          <Grid item xs={12} xl={6}>
            {data && Array.isArray(data) && data.length > 0 && (
              <div style={{ height: InputWidth, width: "100%" }}>
                <DataGrid
                  rows={getData()}
                  columns={UserListMetaData}
                  pageSize={6}
                  rowsPerPageOptions={[100]}
                  checkboxSelection
                  onSelectionModelChange={(ids) => setSelectedRows(ids)}
                />
              </div>
            )}
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default ViewUserList;
