import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PermIdentity, ShoppingCart, Menu } from "@mui/icons-material";
import { Button, Badge, Drawer } from "@mui/material";
import { Input } from "../../atoms/Input/Input";
import { ProductContext } from "../../../Contexts/ProductContext";
import "./Header.css";
export const Header = () => {
  const [searchValue, setSearchvalue] = useState("");
  const [showDrawer, setShwoDrawer] = useState(false);
  const navigate = useNavigate();
  const { cart, user, setUser, getUser, getCart, setSearch } =
    useContext(ProductContext);
  console.log("cart details::", cart);
  console.log("user details::", user);
  const handleClick = (link) => {
    console.log("handleCLick::", link);
    if (link === "/logout") {
      localStorage.removeItem("token");
      setUser({});
      getCart();
      navigate("/")
      return;
    }
    if (link === "/my-orders") {
      navigate("/login");
    }
    navigate(link);
    setShwoDrawer(false);
  };
  const getHeaders = () => {
    const headList = [
      { link: "/most-selling", label: "Most Selling" },
      { link: "/new-arrivals", label: "New Arrivals" },
      { link: "/user-home", label: "My Profile" },
    ];
    if (user?.admin) {
      headList.push({ link: "/dashboard", label: "My Dashboard" });
    }
    headList.push({ link: "/my-orders", label: "My Orders" });
    if (user && Object.keys(user).length > 0) {
      headList.push({ link: "/logout", label: "Logout" });
    }
    return headList;
  };

  const handleIconClicked = (name) => {
    const token = localStorage.getItem("token");
    console.log("token", token);

    let loc = "/login";
    if (token) {
      if (name === "cart") loc = "/cart";
      else if (name === "user") loc = "/user-home";
    }
    navigate(loc);
  };
  const handleClickHome = () => {
    navigate("/");
  };
  const handleSearch = () => {
    setSearch({ flag: true, text: searchValue });
    navigate("/search/" + searchValue);
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && Object.keys(user).length <= 0) {
      getUser();
    }
  }, [user]);
  console.log("user details::", user);
  return (
    <div className="header-wrapper">
      <div className="logo" onClick={handleClickHome}>
        <img src="/gold.svg" alt="logo" />
      </div>
      <div className="flex1">
        <div className="main-header-mini">
          <Button
            onClick={() => handleIconClicked("user")}
            variant="text"
            size="large"
            sx={{ textTransform: "none", color: "#fff", fontWeight: 400 }}
            endIcon={<PermIdentity sx={{ fontSize: 35 }} />}
          >
            <small>Hello, {user?.name || "Sign in"}</small>
          </Button>
          <Badge badgeContent={cart.length} color="primary">
            <ShoppingCart
              onClick={() => handleIconClicked("cart")}
              sx={{ fontSize: 30 }}
            />
          </Badge>
          <Menu onClick={(e) => setShwoDrawer(true)} />
        </div>
        <div className="main-header-mini-2">
          <div className="search-mini">
            <Input
              value={searchValue}
              setValue={setSearchvalue}
              click={handleSearch}
              placeholder="Search for products"
            />
          </div>
        </div>
        <section className="main-header">
          <div className="search">
            <Input
              value={searchValue}
              setValue={setSearchvalue}
              click={handleSearch}
              placeholder="Search for products, brands and more"
            />
          </div>
          <div className="profile">
            <Button
              variant="text"
              name="user"
              onClick={(e) => handleIconClicked("user")}
            >
              <div className="account-section">
                <PermIdentity sx={{ fontSize: 30 }} />
                <div className="navbar-account">
                  <small>Hello, {user?.name || "Sign in"}</small>
                  <br />
                  My Account
                </div>
              </div>
            </Button>
            <div className="cart-wrapper">
              <Badge badgeContent={cart.length} color="primary">
                <ShoppingCart
                  onClick={(e) => handleIconClicked("cart")}
                  sx={{ fontSize: 30 }}
                />
              </Badge>
            </div>
          </div>
        </section>
        <section className="category-section">
          {getHeaders().map((list, idx) => (
            <Button
              onClick={(e) => handleClick(list.link)}
              key={list.label + idx}
              variant="text"
            >
              <span className="category-titles">{list.label}</span>
            </Button>
          ))}
        </section>
      </div>
      <Drawer
        anchor={"right"}
        open={showDrawer}
        onClose={(e) => setShwoDrawer(false)}
      >
        <div className="drawer-wrapper">
          {getHeaders().map((list, idx) => (
            <div className="drawer-item">
              <Button
                onClick={(e) => handleClick(list.link)}
                key={list.label + idx}
                variant="text"
              >
                <span className="category-titles">{list.label}</span>
              </Button>
            </div>
          ))}
        </div>
      </Drawer>
    </div>
  );
};
