import React from "react";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  ListItemText,
  OutlinedInput,
  Checkbox,
} from "@mui/material";
import { InputWidth } from "../../../helpers/constants";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Dropdown({ label, selected, id, change, data, multiple = false }) {
  const handleChange = (e) => {
    const { value } = e.target;
    change(typeof value === "string" ? value.split(",") : value);
  };
  return (
    <FormControl sx={{ m: 1, margin: 0, width: "100%" }}>
      <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-helper-label"
        id={"simple-select-" + id}
        value={selected}
        multiple={multiple}
        label={label}
        onChange={handleChange}
        input={<OutlinedInput label="Tag" />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        {data.map(({ id, name }) => (
          <MenuItem key={id} value={name}>
            <Checkbox checked={selected.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default Dropdown;
