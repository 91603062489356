const baseUrl = {
  local: "http://localhost:3500",
  dev: "https://ayraas-fashion-web-service.onrender.com",
  prod: "https://ayraasfashion.glitch.me/",
  prod2: "https://odd-teal-magpie-gear.cyclic.app"
};
export const getBaseUrl = () => {
  const env = document.location.hostname;
  let baseUrlType = "prod";
  // if (env === "localhost") baseUrlType = "local";
  return baseUrl[baseUrlType];
};

export const endpoints = {
  createCategory: "/dashboard/create-category",
  getCategories: "/dashboard/get-categories",
  uploadImages: "/dashboard/upload",
  createProduct: "/dashboard/addProduct",
  totalProducts: "/dashboard/get-total-products",
  getAllProduct: "/product/getAllProduct",
  mostSellingProducts: "/product/mostSellingProducts",
  getProduct: "/product/getProductById/:id",
  register: "/user/register",
  forgotPassword: "/user/forgot-password",
  verify: "/user/verify",
  login: "/user/login",
  changePassword: "/user/change-password",
  getAccounts: "/user/getAccount",
  addAddress: "/user/add-address",
  removeAddress: "/user/address/remove/:id",
  setAddDefault: "/user/address/set-default/:id",
  profileUpdate: "/user/update",
  addToCart: "/order/addToCart",
  getCart: "/order/getCart",
  removeCartItem: "/order/removeFromCart/:_id",
  updateAddress: "/order/updateAddress",
  placeOrder: "/order/placeOrder",
  cancelOrder: "/order/cancel",
  myOrders: "/order/getOrders",
  orders: "/order/getAllOrders",
  addFeedback: "/order/addFeedback",
  updateStatus: "/order/updateStatus",
  listProducts: "/dashboard/listProducts",
  deleteProducts: "/dashboard/deleteProduct",
  createUser: "/dashboard/createUser",
  userList: "/dashboard/userList",
  getAllSales: "/dashboard/get-sales",
  updateQuantity: "/dashboard/update/qty",
  updateYoutubeLink: "/dashboard/update/youtube",
  deleteUser: "/dashboard/deleteUsers",
  getProductByCategory: "/product/getProductsByCategory/:category",
  search: "/product/search/:key",
};
