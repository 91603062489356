import React from "react";
import { Grid } from "@mui/material";
import { useApiCall } from "../../hooks/useApiCall";
import { endpoints } from "../../services/apis";
import { ProductItem } from "../organisms/ProductItem/ProductItem";
import Loader from "../molecules/Loader/Loader";

function MostSelling() {
    const [products=[], loading] = useApiCall({url:endpoints.mostSellingProducts})
  console.log("MostSelling::products::", products);
  return (
    <div className="main-area">
      <div className="category-wrapper">
        <div className="newArrivals capitalize">Most Selling Products</div>
        <Loader loading={loading} />
        {!loading && <Grid container spacing={1}>
          {products?.map((product) => (
            <Grid key={product._id} item xs={6} sm={6} md={4} lg={3} xl={2}>
              <ProductItem product={product} />
            </Grid>
          ))}
        </Grid>}
      </div>
    </div>
  );
}

export default MostSelling;
