import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  TextField,
  IconButton,
  Autocomplete,
  Chip,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Sizes } from "../../../helpers/constants";
import Dropdown from "../../atoms/Dropdown/Dropdown";
import DragDrop from "../DragDrop/DragDrop";
import { commonGet, commonPost } from "../../../services/common";
import { endpoints } from "../../../services/apis";
import { uploadImages } from "../../../helpers/commonFun";
import Loader from "../../molecules/Loader/Loader";

function AddProducts() {
  const [productDetails, setProductDetails] = useState({
    designDetails: [],
    materialCare: ["Do not bleach"],
    productName: "",
    productDescription: "",
    displayPrice: 0,
    productMRP: 0,
    productActualPrice: 0,
    productLink: "",
    qty: 0,
    category: "",
    tags: [],
    productId: "",
    youtube: "",
    images: [],
    colorOptions: [],
  });
  const [designDetail, setDesignDetail] = useState("");
  const [materialCare, setMaterialCare] = useState("");
  const [sizes, setSizes] = useState([]);
  const [tagString, setTagString] = useState("");
  const [categories, setCategories] = useState([]);
  const [loading, setLoader] = useState(false);
  const [totalProducts, setTotalProducts] = useState(0);

  const handleAddTags = (e) => {
    const { value } = e.target;
    const tagList = value.split(";");
    setTagString(value);
    setProductDetails({
      ...productDetails,
      tags: tagList.slice(0, tagList.length - 1),
    });
  };

  const handleImageUpload = async (files) => {
    console.log(files);
    const filesCount = files.length;
    const filesArr = [];
    for (let i = 0; i < filesCount; i++) {
      filesArr.push(files.item(i));
    }
    return await uploadImages(filesArr);
  };

  const saveProductImage = async (files) => {
    const urls = await handleImageUpload(files);
    const imgArr = [...productDetails.images, ...urls];
    setProductDetails({ ...productDetails, images: imgArr });
  };
  console.log('images::colors',productDetails.images,productDetails.colorOptions)
  const saveProductColor = async (files) => {
    const urls = await handleImageUpload(files);
    const imgArr = [...productDetails.colorOptions, ...urls];
    console.log("saveProductColor::", urls, imgArr);

    setProductDetails({ ...productDetails, colorOptions: imgArr });
  };

  const handleSelectCategory = (e, value) => {
    console.log("handleSelectCategory::e::value", e, value);
    setProductDetails({ ...productDetails, category: value.label });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductDetails({ ...productDetails, [name]: value });
  };

  const changeDesignDetails = (e) => {
    const { value } = e.target;
    setDesignDetail(value);
  };

  const changeMaterialCare = (e) => {
    const { value } = e.target;
    setMaterialCare(value);
  };

  const createProduct = async () => {
    setLoader(true);
    const productObj = { ...productDetails, sizes };
    console.log("productObj::", productObj);
    await commonPost(endpoints.createProduct, productObj);
    getProductTotal();
    setLoader(false);
  };

  const addDesignDetails = () => {
    if (!designDetail) return;
    const designData = [...productDetails.designDetails];
    designData.push(designDetail);
    setDesignDetail("");
    setProductDetails({ ...productDetails, designDetails: designData });
  };

  const addMaterailCare = () => {
    if (!materialCare) return;
    const data = [...productDetails.materialCare];
    data.push(materialCare);
    setMaterialCare("");
    setProductDetails({ ...productDetails, materialCare: data });
  };

  const removeDesignDetails = (idx) => {
    const designData = [...productDetails.designDetails];
    designData.splice(idx, 1);
    setProductDetails({ ...productDetails, designDetails: designData });
  };

  const removeMaterialCare = (idx) => {
    const data = [...productDetails.materialCare];
    data.splice(idx, 1);
    setProductDetails({ ...productDetails, materialCare: data });
  };

  const getCategories = async () => {
    const result = await commonGet(endpoints.getCategories);
    console.log("categories::", result[1]);
    const data = result[1].map((d, idx) => ({ ...d, id: idx }));
    setCategories(data);
  };

  const getProductTotal = async () => {
    const result = await commonGet(endpoints.totalProducts);
    console.log("getProductTotal:: result", result[1]);
    setTotalProducts(result[1]);
  };

  console.log("productDetails", productDetails);

  useEffect(() => {
    getCategories();
    getProductTotal();
  }, []);

  return (
    <div className="dash-right">
      <Loader loading={loading} />
      {!loading && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div className="input-item">
              <TextField
                onChange={handleChange}
                sx={{ width: "100%" }}
                value={productDetails.productId}
                id="product-id-id"
                name="productId"
                label="Product Id"
                variant="outlined"
              />
            </div>
            <div className="input-item">
              <TextField
                onChange={handleChange}
                sx={{ width: "100%" }}
                value={productDetails.productName}
                id="product-name-id"
                name="productName"
                label="Product Name"
                variant="outlined"
              />
            </div>
            <div className="input-item">
              <TextField
                onChange={handleChange}
                sx={{ width: "100%" }}
                multiline
                rows={5}
                value={productDetails.productDescription}
                name="productDescription"
                id="product-descr-id"
                label="Product Description"
                variant="outlined"
              />
            </div>
            <div className="input-item">
              <Autocomplete
                disablePortal
                id="combo-box-categories"
                options={categories}
                sx={{ width: "100%" }}
                value={productDetails.category}
                onChange={handleSelectCategory}
                renderInput={(params) => (
                  <TextField {...params} label="Categories" />
                )}
              />
            </div>
            <div className="input-item">
              <TextField
                onChange={handleChange}
                type={"number"}
                sx={{ width: "100%" }}
                value={productDetails.displayPrice}
                name="displayPrice"
                id="product-price-id"
                label="Display Price"
                variant="outlined"
              />
            </div>
            <div className="input-item">
              <TextField
                onChange={handleChange}
                sx={{ width: "100%" }}
                value={productDetails.productMRP}
                name="productMRP"
                type={"number"}
                id="product-mrp-id"
                label="Product MRP"
                variant="outlined"
              />
            </div>
            <div className="input-item">
              <TextField
                onChange={handleChange}
                sx={{ width: "100%" }}
                value={productDetails.productActualPrice}
                name="productActualPrice"
                id="product-actual-price-id"
                type={"number"}
                label="Product Actual Price"
                variant="outlined"
              />
            </div>
            <div className="input-item">
              <Dropdown
                label={"sizes"}
                selected={sizes}
                data={Sizes}
                id="size"
                change={setSizes}
                multiple
              />
            </div>
            <div className="input-item tag">
              {productDetails.tags.map((tag) => (
                <Chip label={tag} variant="outlined" />
              ))}
            </div>
            <div className="input-item">
              <TextField
                onChange={handleAddTags}
                sx={{ width: "100%" }}
                value={tagString}
                name="tags"
                id="product-tags"
                label="tags"
                variant="outlined"
                placeholder="use semi-colon to after each tag"
              />
            </div>
            <div className="input-item">
              <div className="title">Colour options</div>
            </div>
            <div className="input-item">
              <DragDrop handleChange={saveProductColor} />
            </div>
            <div className="input-item">
              <div className="title">Upload images</div>
            </div>
            <div className="input-item">
              <DragDrop handleChange={saveProductImage} />
            </div>
            <div className="input-item">
              <TextField
                onChange={handleChange}
                sx={{ width: "100%" }}
                value={productDetails.qty}
                id="product-quantity-id"
                name="qty"
                type={"number"}
                label="Product Quantity"
                variant="outlined"
              />
            </div>
            <div className="input-item">
              <TextField
                onChange={handleChange}
                sx={{ width: "100%" }}
                value={productDetails.productLink}
                id="product-link-id"
                name="productLink"
                label="Product Link"
                variant="outlined"
              />
            </div>
            <div className="input-item">
              <Button
                variant="contained"
                component="label"
                onClick={(e) =>
                  setProductDetails({ ...productDetails, images: [], colorOptions:[],productLink:"" })
                }
              >
                Reset
              </Button>
            </div>
            <div className="input-item">
              <TextField
                onChange={handleChange}
                sx={{ width: "100%" }}
                value={productDetails.youtube}
                id="product-link-id"
                name="youtube"
                label="youtube Link"
                variant="outlined"
              />
            </div>
            <hr />
            Details
            <hr />
            {productDetails.designDetails &&
              productDetails.designDetails.map((design, idx) => (
                <div className="row sp-between pad-l-r-10">
                  <div className="designItem">{design}</div>
                  <IconButton aria-label="delete" sx={{ width: 56 }}>
                    <RemoveCircleOutlineIcon
                      onClick={(e) => removeDesignDetails(idx)}
                    />
                  </IconButton>
                </div>
              ))}
            <div className="input-item2">
              <TextField
                onChange={changeDesignDetails}
                sx={{ width: "100%" }}
                value={designDetail}
                name="designDetail"
                id="product-basic-details-id"
                label="Design Details"
                variant="outlined"
              />
              <IconButton aria-label="delete" sx={{ width: 56 }}>
                <AddCircleOutlineIcon onClick={addDesignDetails} />
              </IconButton>
            </div>
            {productDetails.materialCare &&
              productDetails.materialCare.map((design, idx) => (
                <div className="row sp-between pad-l-r-10">
                  <div className="designItem">{design}</div>
                  <IconButton aria-label="delete" sx={{ width: 56 }}>
                    <RemoveCircleOutlineIcon
                      onClick={(e) => removeMaterialCare(idx)}
                    />
                  </IconButton>
                </div>
              ))}
            <div className="input-item2">
              <TextField
                onChange={changeMaterialCare}
                sx={{ width: "100%" }}
                value={materialCare}
                name="materialCare"
                id="product-material-care"
                label="Material & Care"
                variant="outlined"
              />
              <IconButton aria-label="delete" sx={{ width: 56 }}>
                <AddCircleOutlineIcon onClick={addMaterailCare} />
              </IconButton>
            </div>
            <div className="input-item flex-center">
              <Button
                variant="contained"
                component="label"
                onClick={createProduct}
              >
                CREATE
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <h2>Total Products Added: {totalProducts}</h2>
            <div className="img-wrapper">
              {productDetails?.images.map((img, idx) => (
                <div key={idx} className="img-preview-container">
                  <img src={img} alt="" />
                </div>
              ))}
              {productDetails?.colorOptions.map((img, idx) => (
                <div key={idx} className="img-preview-container">
                  <img src={img} alt="" />
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default AddProducts;
