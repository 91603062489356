import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Button, Chip } from "@mui/material";
import { endpoints } from "../../../services/apis";
import { useApiCall } from "../../../hooks/useApiCall";
import Loader from "../../molecules/Loader/Loader";
import CarouselPr from "../../organisms/Carousel/Carousel";
import "./ProductDetails.css";
import { ProductContext } from "../../../Contexts/ProductContext";
import CustomDialog from "../../molecules/CustomDialog/CustomDialog";
import { commonPost } from "../../../services/common";
import CustomSnackbar from "../../atoms/CustomSnackbar/CustomSnackbar";
import ShareProduct from "../../molecules/ShareComponent/ShareComponent";
import { ProductInitData } from "../../../helpers/constants";

const ProductDetails = () => {
  const [product, setProduct] = useState(ProductInitData);
  const [selectedProduct, setSelectedProduct] = useState({ quantity: 1 });
  const [selectSize, setSelectSize] = useState("");
  const [selectColor, setSelectColor] = useState(
    product?.colorOptions[0] || ""
  );
  const [viewImage, setViewImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [discount, setDiscount] = useState(0);
  const [snackbar, setSnackbar] = useState({ show: false, msg: "" });
  const { user, getCart } = useContext(ProductContext);
  const { hash } = document.location;
  let hashArr;
  if (hash) hashArr = hash.split("/");
  const id = hashArr[hashArr.length - 1];
  const url = endpoints.getProduct.replace(":id", id);
  const [data, loading] = useApiCall({ url });
  const navigate = useNavigate();

  const quantityArr = product?.qty < 5 ? product.qty : 5;

  const addToCart = async () => {
    if (!user || Object.keys(user).length <= 0) {
      navigate("/login");
      return;
    }
    const { defaultAddress, address: addresses } = user;
    let address = addresses.find(
      ({ addressId }) => addressId === defaultAddress
    );
    if (!address) address = addresses[0];
    if (product?.sizes?.length > 0 && (!selectSize || selectSize === "")) {
      setSnackbar({ msg: "Please select the size first", show: true });
      return false;
    }
    if (product?.colorOptions?.length && (!selectColor || selectColor === "")) {
      setSnackbar({ msg: "Please select the colour first", show: true });
      return false;
    }
    const payload = {
      productId: id,
      size: selectSize,
      color: selectColor,
      quantity: selectedProduct.quantity,
      address,
    };
    await commonPost(endpoints.addToCart, payload);
    getCart();
    return true;
  };

  const handleBuyNow = async () => {
    const addToCartItem = await addToCart();
    console.log("handleBuyNow::val", addToCartItem);
    if (addToCartItem) navigate("/cart");
  };

  const handleChangeSelectProduct = (e) => {
    const { name, value } = e.target;
    setSelectedProduct({ [name]: value });
  };

  const handleClick = (idx, item) => {
    console.log("handleClick::index::item", idx, item);
    setSelectedImage(item.props.src || item.key);
    setViewImage(true);
  };

  useEffect(() => {
    if (data && Array.isArray(data) && data.length > 0) {
      setProduct(data[0]);
    }
  }, [data]);
  console.log("product::", product);

  useEffect(() => {
    if (!product || Object.keys(product).length === 0) return;
    const { productMRP, displayPrice } = product;
    const diff = productMRP - displayPrice;
    const perc = (diff / productMRP) * 100;
    const fixedVal = perc.toFixed(2);
    setDiscount(Number(fixedVal));
  }, [product]);

  let images = product?.images[0]?.split(",");
  if (images && images[images.length - 1] === "")
    images.splice(images.length - 1, 1);
  let imageObj = images?.map((url) => ({ url }));
  const baseUrl = "https://ayraasfashion.com/#/details/";
  return (
    <div className="productDetailsWrapper">
      <Loader loading={loading} />
      {!loading && (
        <Grid container>
          <Grid item xs={12} md={6}>
            <section className="image-section pos-relative">
              <div className="image-inner-mob">
                <CarouselPr click={handleClick} imgArr={imageObj || []} />
              </div>
              <div className="shareIcon">
                <ShareProduct
                  productName={product?.productName}
                  productUrl={baseUrl + id}
                />
              </div>
            </section>
          </Grid>
          <Grid item xs={12} md={6}>
            <section className="height-100 pad-10">
              <div className="product-description-wrapper">
                <h2 className="product_title">{product?.productName} </h2>
                <div className="product_desc">
                  {product?.productDescription}
                </div>
                <div>
                  <p className="pdp-discount-container">
                    <span className="pdp-discount pad-l-5">{`(${discount}% OFF)`}</span>
                    <span className="pdp-price">
                      <strong> {" ₹" + product?.displayPrice}</strong>
                    </span>
                    <div className="pdp-mrp pad-l-5">
                      MRP <span>{"₹" + product?.productMRP}</span>
                    </div>
                  </p>
                </div>
                {product?.sizes?.length? (<div className="select-size">
                  <h4 className="size-button-select-size">SELECT SIZE</h4>
                  <div className="flex size-btn-wrapper">
                    {product?.sizes?.map((size) => (
                      <div
                        key={size}
                        onClick={() => setSelectSize(size)}
                        className={`size-btn center pointer ${
                          selectSize === size ? "size-btn-selected" : ""
                        }`}
                      >
                        {size}
                      </div>
                    ))}
                  </div>
                </div>):null}
                {product?.colorOptions?.length ? (
                  <div className="select-size">
                    <h4 className="size-button-select-size">SELECT COLOUR</h4>
                    <div className="flex size-btn-wrapper">
                      <div className="input-item tag gap-10">
                        {product?.colorOptions?.map((color) => (
                          <div
                            onClick={(e) => setSelectColor(color)}
                            className={`colorSelect ${
                              selectColor === color
                                ? "selectedBorder"
                                : "normalBorder"
                              }`}
                              >
                            <img
                              height="100%"
                              src={color}
                              alt="color selection"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="row pad-10">
                  Quantity:{" "}
                  <select
                    className="mar-l-10"
                    onChange={handleChangeSelectProduct}
                    name="quantity"
                    id="product-quantity"
                  >
                    {[...Array(quantityArr).keys()].map((id) => (
                      <option
                        selected={selectedProduct.quantity === id + 1}
                        key={id + 1}
                        value={id + 1}
                      >
                        {id + 1}
                      </option>
                    ))}
                  </select>
                </div>
                {product?.qty > 0 ? (
                  <div className="buy-btns">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button
                          onClick={handleBuyNow}
                          sx={{ backgroundColor: "#373f50" }}
                          variant="contained"
                          fullWidth
                        >
                          Buy Now
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          onClick={addToCart}
                          fullWidth
                          variant="outlined"
                        >
                          Add to Cart
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                ) : (
                  <div>
                    <span className="a-size-medium a-color-success">
                      {" "}
                      Currently unavailable.{" "}
                    </span>
                    <p>We will notify you once it is available</p>
                  </div>
                )}
                <h3 className="pdp-product-desc-title margin-top-10">
                  Product Details
                </h3>
                {product?.youtube && (
                  <div className="orderCardWrapper">
                    <h3>Video description</h3>
                    <a href={product?.youtube} target="_blank">
                      <img
                        width={"75px"}
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjdB5idttrtpfZkhv3Dz20jvA14SXgOCOKQiBT6_tnEyagCBi91sEJMTU8X6eLxzb5dQ&usqp=CAU"
                        alt="youtube link"
                      />
                    </a>
                  </div>
                )}
                <b> Design Details </b>
                {product?.designDetails?.map((details) => (
                  <p>{details}</p>
                ))}
                <div>
                  <h4 className="pdp-sizeFitDescTitle pdp-product-description-title">
                    Material &amp; Care
                  </h4>
                  {product?.materialAndCare?.map((details) => (
                    <p>{details}</p>
                  ))}
                </div>
              </div>
            </section>
          </Grid>
        </Grid>
      )}
      <CustomSnackbar values={snackbar} setValues={setSnackbar} />
      <CustomDialog open={viewImage} setOpen={setViewImage}>
        <div className="imgWrapper">
          <img src={selectedImage} alt="" />
        </div>
      </CustomDialog>
    </div>
  );
};

export default ProductDetails;
