import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const CarouselPr = ({ imgArr, axis = "horizontal", click }) => {
  return (
    <Carousel
      infiniteLoop
      autoPlay
      axis={axis}
      onClickItem={click}
      onClickThumb={click}
      showThumbs={false}
    >
      {imgArr?.map(({ url, legend }) => (
        <div key={url}>
          <img src={url} />
          {legend && legend !== "" && <p className="legend">{legend}</p>}
        </div>
      ))}
    </Carousel>
  );
};

export default CarouselPr;
