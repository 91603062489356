import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ProductContext } from "../../../Contexts/ProductContext";
import { ImageFrame } from "../../atoms/imageFrame/ImageFrame";
import "./ProductItem.css";

export const ProductItem = ({ product }) => {
  const {
    images,
    productName,
    productDescription,
    displayPrice,
    productMRP,
    _id,
  } = product;
  const [discount, setDiscount] = useState("");
  const navigate = useNavigate();

  const { setProductId } = useContext(ProductContext);

  const handleItemClick = () => {
    setProductId(_id);
    navigate(`/details/${_id}`);
  };
console.log('images::product',product)
  useEffect(() => {
    const diff = productMRP - displayPrice;
    const perc = parseInt((diff / productMRP) * 100);
    setDiscount(perc);
  }, []);
  return (
    <section className="productItem" onClick={handleItemClick}>
      <ImageFrame frame={1} img={images[0].split(",")[0]} />
      <h3 className="p-item-title text-center">{productName}</h3>
      <div className="priceSection">
        <div className="selling-p">
          <span>₹</span>
          {displayPrice}
        </div>
        <div className="actual-p">₹{productMRP}</div>
        <div className="pdp-discount">{`(${discount}% off)`}</div>
      </div>
    </section>
  );
};
