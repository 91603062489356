import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Profile from "../../molecules/Profile/Profile";
import { Grid } from "@mui/material";
import Addresses from "../../molecules/Addressees/Addresses";
import OrderList from "../../organisms/OrderList/OrderList";
import { ProductContext } from "../../../Contexts/ProductContext";
import ChangePassword from "../../organisms/LoginCard/ChangePassword";
import CreateCategory from "../../organisms/CreateCategory/CreateCategory";
import AddProducts from "../../organisms/AddProducts/AddProducts";
import Orders from "../../organisms/Orders/Orders";
import ProductList from "../../organisms/ProductList/ProductList";
import CreateUser from "../../organisms/CreateUser";
import ViewUserList from "../../organisms/ViewUserList";
import Dashboard from "../../organisms/Dashboard";

function Account({ menus }) {
  const [selectedMenu, setSelectedMenu] = useState(0);
  const { user } = useContext(ProductContext);
  const navigate = useNavigate();
  console.log("selectedMenu::", menus, selectedMenu);
  const handleSelectMenu = (selected) => {
    setSelectedMenu(selected);
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log("account::user name not found", user);
      navigate("/");
    }
  }, []);
  return (
    <div className="pr-bg-area">
      <h3>Welcome, {user?.name}</h3>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <div className="menu-wrapper">
            <div className="title">Account</div>
            <div className="account-menu-item-wrapper">
              {menus.map((menu, idx) => (
                <div
                  key={menu.id}
                  className={`menu-item ${
                    selectedMenu === idx ? "selected" : ""
                  }`}
                  onClick={() => handleSelectMenu(idx)}
                >
                  {menu?.label}
                </div>
              ))}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <main className="mainWrapper">
            <h2>{menus[selectedMenu].label}</h2>
            {menus[selectedMenu].id === 1 && <Profile />}
            {menus[selectedMenu].id === 2 && <Addresses />}
            {menus[selectedMenu].id === 3 && <OrderList isAdmin={false} />}
            {menus[selectedMenu].id === 5 && <ChangePassword />}
            {menus[selectedMenu].id === 11 && <CreateCategory />}
            {menus[selectedMenu].id === 12 && <AddProducts />}
            {menus[selectedMenu].id === 13 && <Orders />}
            {menus[selectedMenu].id === 14 && <ProductList />}
            {menus[selectedMenu].id === 15 && <CreateUser />}
            {menus[selectedMenu].id === 16 && <ViewUserList />}
            {menus[selectedMenu].id === 17 && <Dashboard />}
          </main>
        </Grid>
      </Grid>
    </div>
  );
}

export default Account;
