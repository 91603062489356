import React, { useEffect, useState } from "react";
import OrderCard from "../../molecules/OrderCard/OrderCard";
import { endpoints } from "../../../services/apis";
import { useApiCall } from "../../../hooks/useApiCall";
import Loader from "../../molecules/Loader/Loader";
import { MenuItem, Select } from "@mui/material";
import { StatusType } from "../../../helpers/constants";

function OrderList({ isAdmin }) {
  const [selectedStatus, setSelectedStatus] = useState(-1);
  const [orders, setOrders] = useState({});
  const url = isAdmin ? endpoints.orders : endpoints.myOrders;
  const method = "get";
  const [data, loading, error, update] = useApiCall({ url, method });

  const handleChange = async (e) => {
    const { value } = e.target;
    console.log("handleChange:: value", value, data);
    setSelectedStatus(value);
    if (value === "-1") setOrders(data);
    else {
      const result = {};
      Object.keys(data).forEach((key) => {
        const orderDataItem = data[key].filter(
          ({ status }) => status === value
        );
        if (orderDataItem.length > 0) result[key] = orderDataItem;
      });
      setOrders(result);
    }
  };
  console.log("orders::", data);

  useEffect(() => {
    if (data) {
      setOrders(data);
    }
  }, [data]);
  return (
    <div className="orderListWrapper">
      <Loader loading={loading} />
      {isAdmin && (
        <Select
          labelId="demo-simple-select-label"
          id="status-filter-id"
          value={selectedStatus}
          label="Status"
          onChange={handleChange}
          fullWidth
        >
          {StatusType.map(({ value, label }) => (
            <MenuItem value={value}>{label}</MenuItem>
          ))}
        </Select>
      )}
      <div className="pad-10"></div>
      {Object.keys(orders)
        .reverse()
        .map((date) => (
          <OrderCard
            key={date}
            isAdmin={isAdmin}
            date={date}
            order={orders[date]}
            update={update}
          />
        ))}
    </div>
  );
}

export default OrderList;
