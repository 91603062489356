import React, { useState } from "react";
import { PaymentMod, StatusType } from "../../helpers/constants";
import CustDatePicker from "../molecules/DatePicker/CustDatePicker";
import { Button, MenuItem, Select } from "@mui/material";
import { commonPost } from "../../services/common";
import { endpoints } from "../../services/apis";
import dayjs from "dayjs";
import moment from "moment";

function OrderViewByAdmin({ product, productDetails, update, setViewAddress }) {
  const [date, setDate] = useState(
    dayjs(moment(product?.expected_date).format("MM[/]DD[/]YYYY"))
  );
  const [selectedStatus, setSelectedStatus] = useState(product.status);
  const { name, phone, address1, address2, landMark, pin, state, town } =
    product.address;
  const constructAddress = () => {
    return town + " " + state + " " + pin;
  };
  const handleDateChange = (newVal) => {
    console.log("newVal::", newVal);
    setDate(newVal);
  };
  const handleChange = async (e) => {
    const { value } = e.target;
    setSelectedStatus(value);
  };
  const handleUpdate = async () => {
    if (date && selectedStatus) {
      await commonPost(endpoints.updateStatus, {
        status: selectedStatus,
        orderId: product._id,
        expected_date: new Date(date).getTime(),
      });
    }
    update();
    setViewAddress(false);
  };
  console.log("OrderViewByAdmin::date", date);

  return (
    <div className="order-details-wrapper">
      <div className="wrapper flex center">
        <img src={product?.image} alt="" />
      </div>
      <div className="row mar-t-20">
        <div className="label">Payment Type:</div>
        <div className="label">{PaymentMod[product.paymentMod]}</div>
      </div>
      <div className="row pad-t-10">
        <div className="label">Price:</div>
        <div className="label">{product?.price}</div>
      </div>
      <div className="row pad-t-10">
        <div className="label">Quantity:</div>
        <div className="label">{product?.quantity}</div>
      </div>
      <div className="row pad-t-10">
        <div className="label">Size:</div>
        <div className="label">{product?.size}</div>
      </div>
      <div className="row pad-t-10">
        <div className="label">Color:</div>
        <div className="label">
          <img width={50} src={product?.color} alt="" />
        </div>
      </div>
      <div className="row pad-t-10">
        <div className="label">ProductLink:</div>
        <div className="label">
          <a href={productDetails?.productLink} target="_blank">
            {productDetails?.productLink}
          </a>
        </div>
      </div>
      <div className="title pad-t-10">Address:</div>
      <div className="address-wrapper-dash box-border">
        <div className="title">{name}</div>
        <div className="address">{address1}</div>
        <div className="address">{address2}</div>
        <div className="address">{constructAddress()}</div>
        <div className="address">Phone number: {phone}</div>
        <div className="address">Land mark: {landMark}</div>
      </div>
      <div className="pad-t-10">
        <CustDatePicker value={date} handleChange={handleDateChange} />
      </div>
      <div className="pad-t-10">
        <Select
          labelId="demo-simple-select-label"
          id="status-filter-id"
          value={selectedStatus}
          label="Status"
          onChange={handleChange}
          fullWidth
        >
          {StatusType.slice(1, StatusType.length).map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="pad-t-10">
        <Button
          onClick={handleUpdate}
          variant="contained"
          size="small"
          fullWidth
          color="info"
        >
          Update
        </Button>
      </div>
    </div>
  );
}

export default OrderViewByAdmin;
