import React from "react";
import { Button } from "@mui/material";
import "./Addresses.css";
import { commonGet } from "../../../services/common";
import { endpoints } from "../../../services/apis";

function Address({
  address,
  deliverBtn,
  defaultId,
  edit,
  defaultText,
  getNew,
}) {
  const {
    name,
    phone,
    address1,
    address2,
    landMark,
    pin,
    state,
    town,
    addressId,
  } = address;
  const isDefaultAddress = defaultId === addressId;
  const constructAddress = () => {
    return town + " " + state + " " + pin;
  };

  const handleAddressRemove = async () => {
    const payload = { ":id": addressId };
    await commonGet(endpoints.removeAddress, payload);
    getNew();
  };
  const handleSetDefault = async () => {
    const payload = { ":id": addressId };
    await commonGet(endpoints.setAddDefault, payload);
    getNew();
  };
  console.log("id comparison", defaultId, addressId);

  return (
    <div className="box-border">
      <div className={`addTile ${isDefaultAddress ? "selectedBorder" : ""}`}>
        <div className="title">{name}</div>
        <div className="address">{address1}</div>
        <div className="address">{address2}</div>
        <div className="address">{constructAddress()}</div>
        <div className="address">Phone number: {phone}</div>
        <div className="address">Land mark: {landMark}</div>
        <div className="footer-btn">
          <Button
            onClick={(e) => edit({ ...address, id: addressId })}
            variant="contained"
            size="small"
            color="info"
          >
            Edit
          </Button>
          {!deliverBtn && (
            <Button
              onClick={handleAddressRemove}
              variant="outlined"
              size="small"
              color="error"
            >
              Remove
            </Button>
          )}
          {!isDefaultAddress && (
            <Button
              onClick={handleSetDefault}
              variant="contained"
              size="small"
              color="success"
            >
              {defaultText ? defaultText : "Set as Default"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Address;
