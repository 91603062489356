import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["JPG", "PNG", "JPEG", "webp", "avif"];

function DragDrop({ handleChange, multiple = true }) {
  return (
    <FileUploader
      multiple={multiple}
      handleChange={handleChange}
      name="file"
      types={fileTypes}
      files={null}
    />
  );
}

export default DragDrop;
