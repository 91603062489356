import React, { useState, useContext } from "react";
import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CustomDialog from "../CustomDialog/CustomDialog";
import Address from "./Address";
import "./Addresses.css";
import NewAddress from "../NewAddress/NewAddress";
import { ProductContext } from "../../../Contexts/ProductContext";

function Addresses() {
  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const [editData, setEditData] = useState({});
  const { user, getUser } = useContext(ProductContext);
  const { address, defaultAddress } = user;
  const handleAddressDialog = () => {
    setOpenAddressDialog(!openAddressDialog);
  };
  const handleEditAddress = (value) => {
    setEditData(value);
    setOpenAddressDialog(true);
  };
  return (
    <div className="address-wrapper">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <div className="a-box addTile" onClick={handleAddressDialog}>
            <div className="a-box-inner a-padding-extra-large">
              <AddIcon />
              <h2 className="a-color-tertiary">Add address</h2>
            </div>
          </div>
        </Grid>
        {address.reverse().map((add) => (
          <Grid justifyContent="center" item xs={12} sm={6} md={6} lg={4}>
            <Address
              getNew={getUser}
              edit={handleEditAddress}
              address={add}
              key={add.addressId}
              defaultId={defaultAddress}
            />
          </Grid>
        ))}
      </Grid>
      <CustomDialog
        open={openAddressDialog}
        title={"Add a new address"}
        setOpen={setOpenAddressDialog}
      >
        <NewAddress
          getNew={getUser}
          data={editData}
          close={setOpenAddressDialog}
        />
      </CustomDialog>
    </div>
  );
}

export default Addresses;
