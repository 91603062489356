import { useState, useEffect } from "react";
import { commonGet, commonPost } from "../services/common";

export const useApiCall = ({ method = "get", url, payload }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      let response;
      if (method === "get") {
        response = await commonGet(url);
      } else if (method === "post") {
        response = await commonPost(url, payload);
      }
      if (response[1]) setData(response[1]);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [method, url, payload]);
  const update = async () => {
    await fetchData();
  };
  return [data, loading, error, update];
};
