import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { ProductContext } from "../../../Contexts/ProductContext";
import { getAllProducts } from "../../../services/ProductServices";
import { isArrayValid } from "../../../helpers/commonFun";
import { ProductItem } from "../../organisms/ProductItem/ProductItem";
import Loader from "../../molecules/Loader/Loader";

function NewArrivals() {
  const { products, setProducts } = useContext(ProductContext);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setLoader(true);
    setProducts([]);
    getAllProducts()
      .then((res = []) => {
        if (isArrayValid(res)) setProducts(res[1]);
        setLoader(false)
      })
      .catch((e) => {
        setProducts([]);
        setLoader(false)
      });
  }, []);
  console.log("products::", products);
  return (
    <div className="main-area">
      <div className="category-wrapper">
        <div className="newArrivals capitalize">New Arrivals</div>
        <Loader loading={loader} />
        {!loader && <Grid container spacing={1}>
          {products?.map((product) => (
            <Grid key={product._id} item xs={6} sm={6} md={4} lg={3} xl={2}>
              <ProductItem product={product} />
            </Grid>
          ))}
        </Grid>}
      </div>
    </div>
  );
}

export default NewArrivals;
