import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { ProductContext } from "../../../Contexts/ProductContext";
import { commonPost } from "../../../services/common";
import { endpoints } from "../../../services/apis";
import Address from "../../molecules/Addressees/Address";
import CustomDialog from "../../molecules/CustomDialog/CustomDialog";
import NewAddress from "../../molecules/NewAddress/NewAddress";
import CustomSnackbar from "../../atoms/CustomSnackbar/CustomSnackbar";

function AddressSelect() {
  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const [editData, setEditData] = useState({});
  const [snackbar, setSnackbar] = useState({ show: false, msg: "" });
  const { user, getUser, cart } = useContext(ProductContext);
  const { address, defaultAddress } = user;
  const navigate = useNavigate();
  const handleSubmit = async () => {
    const { address, defaultAddress } = user;
    const selectedAdd = address.find(
      ({ addressId }) => addressId === defaultAddress
    );
    console.log("selectedAddress::", selectedAdd);
    if (!selectedAdd) {
      setSnackbar({ show: true, msg: "please select an Address or Add one" });
      return;
    }
    const ids = cart.map(({ _id }) => _id);
    await commonPost(endpoints.updateAddress, { address: selectedAdd, ids });
    navigate("/buy/payment/handlers");
  };
  const handleAddressDialog = () => {
    setOpenAddressDialog(!openAddressDialog);
  };
  const handleEditAddress = (value) => {
    setEditData(value);
    setOpenAddressDialog(true);
  };
  return (
    <div id="addressSelect">
      <section>
        <Grid justifyContent="center" container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <div className="title pad-20">Select a delivery address</div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}></Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              fullWidth
              variant="contained"
              //   color="success"
              sx={{ maxWidth: 400, backgroundColor: "#0b8ace" }}
              onClick={handleSubmit}
            >
              Proceed
            </Button>
          </Grid>
        </Grid>

        <div className="address-wrapper">
          <Grid justifyContent="center" container spacing={2}>
            {address.reverse().map((add) => (
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Address
                  getNew={getUser}
                  edit={handleEditAddress}
                  address={add}
                  key={add.addressId}
                  defaultId={defaultAddress}
                  defaultText="Select"
                  deliverBtn
                />
              </Grid>
            ))}
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Button onClick={handleAddressDialog} variant="text">
                Add a New Address
              </Button>
            </Grid>
          </Grid>
          <CustomDialog
            open={openAddressDialog}
            title={"Add a new address"}
            setOpen={setOpenAddressDialog}
          >
            <NewAddress
              getNew={getUser}
              data={editData}
              close={setOpenAddressDialog}
            />
          </CustomDialog>
        </div>
      </section>
      <CustomSnackbar values={snackbar} setValues={setSnackbar} />
    </div>
  );
}

export default AddressSelect;
