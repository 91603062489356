import { endpoints } from "./apis";
import { commonPost } from "./common";

export const register = async (payload) => {
  try {
    console.log("register::payload", payload);
    const [, resp] = await commonPost(endpoints.register, payload);
    console.log("register::resp", resp);
    return resp;
  } catch (e) {
    return false;
  }
};

export const verifyOtp = async (payload) => {
  try {
    const [, resp] = await commonPost(endpoints.verify, payload);
    console.log("register::resp", resp);
    if (resp?.token) {
      const { accessToken } = resp.token;
      localStorage.setItem("token", accessToken);
      return resp;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const forgotPassword = async (payload) => {
  try {
    const [, resp] = await commonPost(endpoints.forgotPassword, payload);
    console.log("changePassword::resp", resp);
    return resp;
  } catch (e) {
    return false;
  }
};

export const changePassword = async (payload) => {
  try {
    const [, resp] = await commonPost(endpoints.changePassword, payload);
    console.log("changePassword::resp", resp);
    return resp;
  } catch (e) {
    return false;
  }
};

export const login = async (payload) => {
  try {
    const [, resp] = await commonPost(endpoints.login, payload);
    console.log("login::resp", resp);
    if (resp?.token) {
      const { accessToken } = resp.token;
      localStorage.setItem("token", accessToken);
    }
    return resp;
  } catch (e) {
    return false;
  }
};
