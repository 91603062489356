import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginCard from "../../organisms/LoginCard/LoginCard";
import RegisterCard from "../../organisms/LoginCard/RegisterCard";
import ForgotPassword from "../../organisms/LoginCard/ForgotPassword";
import ChangePassword from "../../organisms/LoginCard/ChangePassword";
import VerifyPhone from "../../organisms/LoginCard/VerifyPhone";

function LoginRegister({ pageNo = 1 }) {
  const [page, setpage] = useState(1);
  const navigate = useNavigate();
  const pageMap = {
    Login: 1,
    Register: 2,
    ForgotPassword: 3,
    ChangePassword: 4,
    verifyPhone: 5,
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/user-home");
    }
  }, []);
  return (
    <div className="sec-bg-area">
      <div className="container center">
        {page === pageMap.Login && <LoginCard changePage={setpage} />}
        {page === pageMap.Register && <RegisterCard changePage={setpage} />}
        {page === pageMap.verifyPhone && <VerifyPhone changePage={setpage} />}
        {page === pageMap.ForgotPassword && (
          <ForgotPassword changePage={setpage} />
        )}
        {page === pageMap.ChangePassword && (
          <ChangePassword changePage={setpage} />
        )}
      </div>
    </div>
  );
}

export default LoginRegister;
