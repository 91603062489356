import React from "react";
import { endpoints } from "../../services/apis";
import { useApiCall } from "../../hooks/useApiCall";
import { Grid } from "@mui/material";
import { ProductItem } from "../organisms/ProductItem/ProductItem";
import Loader from "../molecules/Loader/Loader";

function Search() {
  const { hash } = document.location;
  let hashArr;
  if (hash) hashArr = hash.split("/");
  const search = decodeURIComponent(hashArr[hashArr.length - 1]);
  const url = endpoints.search.replace(":key", search) || "";
  const [data, loading] = useApiCall({ url });
  return (
    <div className="main-area">
      <Loader loading={loading} />
      {!loading && Array.isArray(data) && (
        <div className="category-wrapper">
          <div className="newArrivals capitalize">{search}</div>
          {!data ||
            (data.length === 0 && <p>Sorry, couldn't find the product</p>)}
          <Grid container spacing={1}>
            {data?.map((product) => (
              <Grid key={product._id} item xs={6} sm={6} md={4} lg={3} xl={2}>
                <ProductItem product={product} />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </div>
  );
}

export default Search;
