import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import moment from "moment";

export default function OrderTimeline({ stages, image }) {
  console.log("OrderTimeline::stages", stages, image);
  return (
    <div className="orderStatusWrapper">
      <Timeline position="right">
        {stages.map((stage) => (
          <TimelineItem>
            <TimelineOppositeContent color="text.secondary">
              {moment(Number(stage?.cr_date)).format("Do MMM")}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>{stage?.status_name}</TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </div>
  );
}
