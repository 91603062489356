import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Link, Checkbox } from "@mui/material";
import "./LoginCard.css";
import { register } from "../../../services/authenticationService";

function RegisterCard({ changePage }) {
  const [userInput, setUserInput] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("handleInputChange::e", name, value);
    if (name === "phone" && value.length > 10) return;
    setUserInput({ ...userInput, [name]: value });
  };
  const handleRegister = async () => {
    if (!checked) return;
    const isCountryCodeAvailable = userInput.phone.includes("+");
    if (!isCountryCodeAvailable) userInput.phone = "+91" + userInput.phone;
    const data = await register(userInput);
    if (data.userExisting && data.active) {
      navigate("/login");
      changePage(1);
      return;
    }
    localStorage.setItem("phone", userInput.phone);
    navigate("/register/verify");
    changePage(5);
  };
  const isBtnDisabled = () => {
    const { name, phone } = userInput;
    if (!name || name === "" || !phone || phone === "" || !checked) return true;
    return false;
  };
  return (
    <div className="loginWrapper card-shadow">
      <div className="title">
        <h2>Create Account</h2>
      </div>
      <div className="input">
        <TextField
          required
          fullWidth
          value={userInput.name}
          onChange={handleInputChange}
          id="outlined-required"
          label="Name"
          name="name"
          placeholder="Full Name"
        />
      </div>
      <div className="input">
        <TextField
          fullWidth
          name="email"
          onChange={handleInputChange}
          value={userInput.email}
          id="outlined-required"
          label="Email"
          placeholder="Email Address"
        />
      </div>
      <div className="input">
        <TextField
          required
          fullWidth
          inputProps={{ pattern: "^[6-9]d{9}$" }}
          onChange={handleInputChange}
          value={userInput.phone}
          name="phone"
          id="outlined-required"
          label="Phone"
          placeholder="Enter 10 Digit Mobile No"
        />
      </div>
      <div className="input">
        <Checkbox
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
          label="Privacy agreement"
        />
        I hereby agree to the{" "}
        <a target="_blank" href="/#/privacy/policy">
          Terms & Conditions
        </a>
      </div>
      <div className="btn center width-100">
        <Button
          disabled={isBtnDisabled()}
          onClick={handleRegister}
          variant="contained"
          color="success"
        >
          Register
        </Button>
      </div>
      <div className="registerFooter ">
        <Link onClick={() => changePage(1)} underline="hover">
          <div className="helper-text center">
            Already registered? Please Login
          </div>
        </Link>
      </div>
    </div>
  );
}

export default RegisterCard;
