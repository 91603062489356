import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Link } from "@mui/material";
import "./LoginCard.css";
import { changePassword } from "../../../services/authenticationService";

function ChangePassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();

  const handleChangePassword = async () => {
    const resp = await changePassword({ password, confirmPassword });
    if (resp) navigate("/");
  };
  return (
    <div className="loginWrapper card-shadow">
      <div className="title">
        <h2>Change Password</h2>
      </div>
      <div className="input">
        <TextField
          required
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          id="outlined-required"
          label="Password"
          placeholder="New Password"
        />
      </div>
      <div className="input">
        <TextField
          required
          fullWidth
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          type="password"
          id="outlined-required"
          label="Confirm Password"
          placeholder="Confirm New Password"
        />
      </div>
      <div className="btn center width-100">
        <Button
          onClick={handleChangePassword}
          variant="contained"
          color="success"
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default ChangePassword;
