import React, { useState } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { Header } from "./components/organisms/Header/Header";
import { ProductContext } from "./Contexts/ProductContext";
import Home from "./components/pages/Home/Home";
import Footer from "./components/molecules/footer/Footer";
import LoginRegister from "./components/pages/LoginRegister/LoginRegister";
import Account from "./components/pages/Account/Account";
import { AccountMenu, DashboardMenus } from "./helpers/constants";
import ProductDetails from "./components/pages/ProductDetails/ProductDetails";
import MyOrders from "./components/pages/MyOrders/MyOrders";
import Cart from "./components/pages/Cart/Cart";
import { commonGet } from "./services/common";
import { endpoints } from "./services/apis";
import AddressSelect from "./components/pages/AddressSelect/AddressSelect";
import PaymentModSelect from "./components/pages/PaymentPage/PaymentPage";
import NewArrivals from "./components/pages/NewArrivals/NewArrivals";
import OrderFeedback from "./components/pages/OrderFeedback";
import ViewProductByCategory from "./components/pages/ViewProductByCategory";
import Search from "./components/pages/Search";
import Privacy from "./components/pages/privacy";
import MostSelling from "./components/pages/mostSelling";

function App() {
  const [productId, setProductId] = useState("");
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [user, setUser] = useState({});
  const [search, setSearch] = useState({ flag: false, text: "" });
  const getCart = async () => {
    const [, cartItems] = await commonGet(endpoints.getCart);
    setCart(cartItems);
  };
  const getUser = async () => {
    const [, resp] = await commonGet(endpoints.getAccounts);
    setUser(resp?.user || {});
    getCart();
  };
  const contextObj = {
    products,
    setProducts,
    productId,
    setProductId,
    cart,
    user,
    setUser,
    getUser,
    getCart,
    search,
    setSearch,
  };
  return (
    <ProductContext.Provider value={contextObj}>
      <div className="app">
        <Router>
          <Header />
          <div className="mainContentArea">
            <div className="mainAreaHeight">
              <Routes>
                <Route path="/login" element={<LoginRegister pageNo={1} />} />
                <Route path="/my-orders" element={<MyOrders />} />
                <Route path="/new-arrivals" element={<NewArrivals />} />
                <Route path="/most-selling" element={<MostSelling />} />
                <Route path="/search/:key" element={<Search />} />
                <Route
                  path="/categories/:category"
                  element={<ViewProductByCategory />}
                />
                <Route path="/privacy/policy" element={<Privacy />} />
                <Route
                  path="/register/verify"
                  element={<LoginRegister pageNo={3} />}
                />
                <Route
                  path="/register/change-password"
                  element={<LoginRegister pageNo={4} />}
                />
                <Route
                  path="/register"
                  element={<LoginRegister pageNo={2} />}
                />
                <Route path="/cart" element={<Cart />} />
                <Route
                  path="/buy/addressselect/handlers"
                  element={<AddressSelect />}
                />
                <Route
                  path="/buy/payment/handlers"
                  element={<PaymentModSelect />}
                />
                <Route
                  path="/user-home"
                  element={<Account menus={AccountMenu} />}
                />
                <Route
                  path="/dashboard"
                  element={<Account menus={DashboardMenus} />}
                />
                <Route path="/details/:hash" element={<ProductDetails />} />
                <Route
                  path="/my-orders/feedback/:orderId"
                  element={<OrderFeedback />}
                />
                <Route index element={<Home />} />
              </Routes>
            </div>
            <Footer />
          </div>
        </Router>
      </div>
    </ProductContext.Provider>
  );
}

export default App;
