import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { ProductContext } from "../../../Contexts/ProductContext";
import { commonPost } from "../../../services/common";
import { endpoints } from "../../../services/apis";
import Subtotal from "../../molecules/Subtotal";

function PaymentModSelect() {
  const { cart, getCart } = useContext(ProductContext);
  const navigate = useNavigate();
  const paymentMod = [
    { label: "Credit card", available: false },
    { label: "Debit card", available: false },
    { label: "Net Banking", available: false },
    { label: "Cash on Delivery/Pay on Delivery", available: true },
  ];
  const handlePaymentSelect = (available) => {
    if (!available) alert("This payment method is not available now");
  };
  const handleOrder = async () => {
    const ids = cart.map(({ _id }) => _id);
    await commonPost(endpoints.placeOrder, { ids });
    getCart();
    alert("Order placed!!");
    navigate("/");
  };

  return (
    <div id="addressSelect">
      <Subtotal url="/" click={handleOrder} />
      <section>
        <Grid justifyContent="center" container spacing={2}>
          <Grid item xs={12}>
            <div className="title pad-20">Select payment method</div>
          </Grid>
        </Grid>

        <div className="address-wrapper">
          <Grid justifyContent="center" container spacing={2}>
            {paymentMod.map((mod) => (
              <Grid item xs={12}>
                <div
                  onClick={(e) => handlePaymentSelect(mod.available)}
                  className={`paymentMod box-border ${
                    mod.available ? "selectedItem" : "disabledItem"
                  }`}
                >
                  {mod.label}
                </div>
              </Grid>
            ))}
            <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              sx={{
                maxWidth: 320,
                backgroundColor: "#0b8ace",
                marginBottom: "20px",
              }}
              onClick={handleOrder}
            >
              Order
            </Button>
          </Grid>
          </Grid>
        </div>
      </section>
    </div>
  );
}

export default PaymentModSelect;
