import React from "react";
import ShareIcon from "@mui/icons-material/Share";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "./Share.css";

const ShareProduct = ({ productName, productUrl }) => {
  const shareToFacebook = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      productUrl
    )}`;
    window.open(url, "_blank");
  };

  const shareToTwitter = () => {
    const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      productUrl
    )}&text=${encodeURIComponent(productName)}`;
    window.open(url, "_blank");
  };

  const shareToInstagram = () => {
    const url = `https://www.instagram.com/?url=${encodeURIComponent(
      productUrl
    )}`;
    window.open(url, "_blank");
  };

  const shareToWhatsApp = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      productUrl
    )}`;
    window.open(whatsappUrl);
  };

  return (
    <>
      {/* Hello world */}
      <div className="buttons">
        <button className="main-button">
          <ShareIcon />
        </button>
        <button
          className="twitter-button button"
          onClick={shareToTwitter}
          style={{
            transitionDelay: "0.1s, 0s, 0.1s",
            transitionProperty: "translate, background, box-shadow",
          }}
        >
          <TwitterIcon />
        </button>
        <button
          onClick={shareToFacebook}
          className="messenger-button button"
          style={{
            transitionDelay: "0.3s, 0s, 0.3s",
            transitionProperty: "translate, background, box-shadow",
          }}
        >
          <FacebookIcon />
        </button>
        <button
          className="instagram-button button"
          onClick={shareToInstagram}
          style={{
            transitionDelay: "0.5s, 0s, 0.5s",
            transitionProperty: "translate, background, box-shadow",
          }}
        >
          <InstagramIcon />
        </button>
        <button
          className="whatsapp-button button"
          onClick={shareToWhatsApp}
          style={{
            transitionDelay: "0.7s, 0s, 0.7s",
            transitionProperty: "translate, background, box-shadow",
          }}
        >
          <WhatsAppIcon />
        </button>
      </div>
    </>
  );
};

export default ShareProduct;
