import React, { useContext, useEffect, useState } from "react";
import "./Profile.css";
import { ProductContext } from "../../../Contexts/ProductContext";
import EditIcon from "@mui/icons-material/Edit";
import { Button, IconButton, MenuItem, Select, TextField } from "@mui/material";
import CustomDialog from "../CustomDialog/CustomDialog";
import { Genders } from "../../../helpers/constants";
import { commonPost } from "../../../services/common";
import { endpoints } from "../../../services/apis";

function Profile() {
  const [showEditor, setShowEditor] = useState(false);
  const [profileData, setProfileData] = useState({
    alternatePhone: "",
    email: "",
    gender: "",
  });
  const { user, getUser } = useContext(ProductContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const { name, phone, email, gender, alternatePhone } = user;
  const itemMap = [
    { key: "Phone", value: phone },
    { key: "Alternate Phone", value: alternatePhone },
    { key: "Email", value: email },
    { key: "Gender", value: gender },
  ];
  const handleSubmit = async () => {
    const [st, , msg] = await commonPost(endpoints.profileUpdate, profileData);
    if (st === 200) {
      getUser();
      alert(msg);
    }
    setShowEditor(false);
  };
  const renderInputs = ({ key, value, edit }) => {
    switch (key) {
      case "Alternate Phone":
        return (
          <div className="input-item">
            <TextField
              onChange={handleChange}
              type={"number"}
              sx={{ width: "100%" }}
              value={profileData.alternatePhone}
              name="alternatePhone"
              id="user-alternatePhone-id"
              label="Alternate Phone"
              variant="outlined"
            />
          </div>
        );
      case "Email":
        return (
          <div className="input-item">
            <TextField
              onChange={handleChange}
              sx={{ width: "100%" }}
              value={profileData.email}
              name="email"
              id="user-email-id"
              label="Email"
              variant="outlined"
            />
          </div>
        );
      case "Gender":
        return (
          <div className="input-item">
            <Select
              labelId="demo-gender-select-label"
              value={profileData.gender}
              label="Gender"
              name="gender"
              variant="outlined"
              id="demo-gender-select"
              onChange={handleChange}
              fullWidth
            >
              {Genders.map(({ name, id }) => (
                <MenuItem key={id} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </div>
        );
      default:
        return (
          <div className="input-item" key={key + value}>
            <span>{key}:</span>
            {value && <span>{" " + value}</span>}
          </div>
        );
    }
  };
  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      setProfileData({
        alternatePhone: alternatePhone || "",
        email: email || "",
        gender: gender || "",
      });
    }
  }, [user]);
  return (
    <div className="card-shadow profile-wrapper">
      <div className="full-name a-row">
        <span className="name">{name}</span>
        <IconButton onClick={(e) => setShowEditor(true)} aria-label="edit">
          <EditIcon />
        </IconButton>
      </div>
      {itemMap.map(({ key, value }) => (
        <div key={key + value}>
          <span>{key}:</span>
          {value && <span>{" " + value}</span>}
        </div>
      ))}
      <CustomDialog
        open={showEditor}
        title={"Edit Profile"}
        setOpen={setShowEditor}
      >
        <div className="profileEditor">
          {itemMap.map((item) => renderInputs(item))}
          <div className="center width-100">
            <Button onClick={handleSubmit} variant="contained" color="success">
              Submit
            </Button>
          </div>
        </div>
      </CustomDialog>
    </div>
  );
}

export default Profile;
