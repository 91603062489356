import React from "react";
import OrderList from "../../organisms/OrderList/OrderList";

function MyOrders() {
  return (
    <div className="order-wrapper">
      <h3>My Orders</h3>
      <OrderList />
    </div>
  );
}

export default MyOrders;
