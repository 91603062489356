import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import "./LoginCard/LoginCard.css";
import { commonPost } from "../../services/common";
import { endpoints } from "../../services/apis";

function CreateUser() {
  const [userInput, setUserInput] = useState({
    name: "",
    password: "",
    phone: "",
  });
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setUserInput({ ...userInput, [name]: value });
  };
  const handleCreateUser = async () => {
    const { createUser } = endpoints;
    const inputs = { ...userInput };
    const { phone } = inputs;
    const isCodeAvailable = phone.indexOf("+91") === -1;
    if (phone && !isNaN(phone) && isCodeAvailable) {
      inputs.phone = "+91" + phone;
    }
    const [status, , msg] = await commonPost(createUser, inputs);
    if (status === 200) alert("user created");
    else alert(msg);
    setUserInput({ name: "", password: "", phone: "" });
  };
  return (
    <div className="loginWrapper card-shadow">
      <div className="input">
        <TextField
          required
          fullWidth
          name="name"
          value={userInput.name}
          onChange={handleInputChange}
          id="outlined-required"
          label="Name"
          placeholder="Enter Name"
        />
      </div>
      <div className="input">
        <TextField
          required
          fullWidth
          name="phone"
          value={userInput.phone}
          onChange={handleInputChange}
          id="outlined-required"
          label="Phone"
          placeholder="Enter Phone"
        />
      </div>
      <div className="input">
        <TextField
          required
          fullWidth
          name="password"
          onChange={handleInputChange}
          value={userInput.password}
          type="password"
          id="outlined-required"
          label="Password"
          placeholder="Enter Password"
        />
      </div>
      <div className="btn center width-100">
        <Button onClick={handleCreateUser} variant="contained" color="success">
          Submit
        </Button>
      </div>
    </div>
  );
}

export default CreateUser;
