import React, { useContext, useEffect } from "react";
import CarouselPr from "../../organisms/Carousel/Carousel";
import CategoryCard from "../../molecules/categoryCard/CategoryCard";
import { Grid } from "@mui/material";
import { ProductContext } from "../../../Contexts/ProductContext";
import { ProductItem } from "../../organisms/ProductItem/ProductItem";
import { useApiCall } from "../../../hooks/useApiCall";
import { endpoints } from "../../../services/apis";
import Loader from "../../molecules/Loader/Loader";

function Home() {
  const { products, setProducts, setSearch } = useContext(ProductContext);
  const [data, loading] = useApiCall({ url: endpoints.getAllProduct });
  const createDriveUrl = (id) => {
    return `https://drive.google.com/uc?export=view&id=${id}`
  }
  const carouselImgs = [
    { url: '/slide1.jpeg', legend: "" },
    { url: '/slide2.jpeg', legend: "" },
    { url: '/slide3.jpeg', legend: "" },
    { url: '/slide4.jpeg', legend: "" },
    { url: '/slide5.jpeg', legend: "" },
  ];
  const categories = [
    {
      label: "sarees",
      img: '/saree-cat.jpeg',
    },
    {
      label: "salwar kameez set",
      img: "salwarKameez-cat.webp",
    },
    {
      label: "kurtis",
      img: 'kurtis-cat.jpeg',
    },
    {
      label: "shirts",
      img: 'shirts-cat.jpeg',
    },
    {
      label: "t shirt",
      img: 'tshirt-cat.jpeg',
    },
  ];
  const handleClick = () => {};
  useEffect(() => {
    if (data && data.length) setProducts(data);
  }, [data]);
  console.log("products::", products);
  return (
    <div className="main-area">
      <Loader loading={loading} />
      <CarouselPr click={handleClick} imgArr={carouselImgs} />
      <div className="category-wrapper">
        <div className="cat-title">Shop by categories</div>
        <Grid justifyContent="center" container spacing={2}>
          <div className="categoryWrapper">
            {categories.map((category) => (
              <Grid key={category} item xs={6} sm={4} lg={3} xl={2}>
                <CategoryCard category={category} />
              </Grid>
            ))}
          </div>
        </Grid>
        <div className="newArrivals">New Arrivals</div>
        <Grid container spacing={1}>
          {products?.map((product) => (
            <Grid key={product._id} item xs={6} sm={6} md={4} lg={3} xl={2}>
              <ProductItem product={product} />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default Home;
