import React, { useEffect, useState } from "react";

import { VerticalBarChart } from "./verticalBarChart";
import { endpoints } from "../../services/apis";
import Loader from "../molecules/Loader/Loader";
import { useApiCall } from "../../hooks/useApiCall";
import SingleDropDown from "../atoms/Dropdown/SingleDropDown";

function Dashboard() {
    const [chartData, setChartData] = useState(null)
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const url = endpoints.getAllSales;
    const [data, loading] = useApiCall({ url });
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const getSalesData = () => {
        const salesMap = {};
        const profitMap = {};
        data.forEach((sales) => {
            const { quantity, actualPrice, price, cr_date } = sales
            const year = new Date(cr_date).getFullYear();
            if (year !== selectedYear) return;
            const month = months[new Date(cr_date).getMonth()];
            const p = Number(price);
            const ap = Number(actualPrice);
            const q = Number(quantity);
            if (!salesMap[month]) {
                salesMap[month] = 0;
                profitMap[month] = 0;
            }
            const revenue = p * q;
            const actualRevenue = ap * q;
            salesMap[month] += revenue;
            profitMap[month] += revenue - actualRevenue;
        });
        console.log('getSalesData',salesMap,profitMap)
        createDataSet(salesMap,profitMap);
    }
    const createDataSet = (salesMap,profitMap) =>{
        if (Object.keys(salesMap).length <= 0) {
        setChartData({labels:[],datasets:[]})
        return 
        }
        const labels = Object.keys(salesMap) || [];
        const datasets = [
            {
                label:"Sales",
                data: Object.values(salesMap) || [],
                backgroundColor: 'rgba(255, 99, 132, 0.5)'
            },
            {
                label:"Profits",
                data: Object.values(profitMap) || [],
                backgroundColor: 'rgba(53, 162, 235, 0.5)'
            }
        ]
        console.log('createDataSet',labels, datasets)
        setChartData({labels,datasets})
    }
    useEffect(() => {
        if (!loading) {
            getSalesData();
        }
    }, [data, loading, selectedYear]);
    const Years = [2022,2023]
    return (
        <div className="productListWrapper">
            <div className="dash-head">
                <SingleDropDown
                    label={"Years"}
                    selected={selectedYear}
                    data={Years}
                    id="year"
                    change={setSelectedYear}
                />
            </div>
            <Loader loading={loading} />
            {!loading && chartData && <VerticalBarChart data={chartData} />}
        </div>
    );
}

export default Dashboard;
