import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { OrderStatus } from "../../../helpers/constants";
import CustomDialog from "../CustomDialog/CustomDialog";
import { commonGet, commonPost } from "../../../services/common";
import { endpoints } from "../../../services/apis";
import moment from "moment";
import OrderViewByAdmin from "../../pages/OrderViewByAdmin";
import OrderTimeline from "../../pages/OrderStatus";

const commonBtnObj = {
  height: 30,
  width: 230,
  textTransform: "none",
  fontWeight: 400,
};
const ColorButton = styled(Button)({
  ...commonBtnObj,
  color: "#000",
  backgroundColor: "#ffd63b",
  "&:hover": {
    backgroundColor: "#9f8522",
  },
});
const NormalButton = styled(Button)({
  color: "#000",
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#EDFDFF",
  },
  ...commonBtnObj,
});
function ProductCard({ product, isAdmin, update }) {
  const [viewAddress, setViewAddress] = useState(false);
  const [productDetails, setProductDetails] = useState({});
  const navigate = useNavigate();

  const getProductById = async () => {
    const { productId } = product;
    const url = endpoints.getProduct.replace(":id", productId);
    const [, result] = await commonGet(url);
    return result;
  };
  const openModalAndGetData = async () => {
    setViewAddress(true);
    const result = await getProductById();
    console.log("productDetails::result", result)
    setProductDetails(result[0]);
  };
  const handleCardClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isAdmin) return;
    openModalAndGetData();
  };
  const handleTrackPackage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (window.innerWidth < 680) return;
    openModalAndGetData();
  };
  const handleShowFeedback = () => {
    navigate("/my-orders/feedback/" + product._id);
  };
  const handleOrderCancel = async (e) => {
    e.stopPropagation();
    const [st] = await commonPost(endpoints.cancelOrder, { id: product._id });
    if (st === 200) {
      alert("Order is getting cancelled");
      window.location.reload();
    }
  };
  const isOrderCancelled = OrderStatus[product.status];
  return (
    <div className="productWrapper">
      <div className="a-row">
        <div className="orderStatus">
          {isOrderCancelled && (
            <span>
              Arriving on{" "}
              {moment(product?.expected_date).format("Do MMM") || "soon"}
            </span>
          )}
          <div>{OrderStatus[product.status] || "Cancelled"}</div>
        </div>
        <div className="track-btns">
          {Number(product.status) > 1 && (
            <ColorButton onClick={handleTrackPackage} variant="contained">
              Track package
            </ColorButton>
          )}
        </div>
      </div>
      <div className="a-row " onClick={handleCardClick}>
        <div className="wrapper">
          <img src={product?.color} alt="" />
        </div>
        <div className="flex1 j-center column pad-l-20">
          <div className="title pad-b-10">{product.productName}</div>
          <div className="desc">{product.productDescription}</div>
          <div className="orderStatus-min">
            {isOrderCancelled && (
              <span>
                Arriving on{" "}
                {moment(product?.expected_date).format("Do MMM") || "soon"}
              </span>
            )}
            <div>{OrderStatus[product.status] || "Cancelled"}</div>
          </div>
        </div>
        <div className="track-btns column flex">
          {Number(product.status) <= 6 && !isAdmin && (
            <NormalButton onClick={handleOrderCancel} variant="contained">
              Cancel item
            </NormalButton>
          )}
          {Number(product.status) === 6 && !isAdmin && (
            <>
              <NormalButton onClick={handleOrderCancel} variant="contained">
                Return Item
              </NormalButton>
              <NormalButton onClick={handleShowFeedback} variant="contained">
                Leave Feedback
              </NormalButton>
            </>
          )}
        </div>
      </div>
      <CustomDialog
        title={product.productName}
        open={viewAddress}
        setOpen={setViewAddress}
      >
        {isAdmin ? (
          <OrderViewByAdmin
            product={product}
            productDetails={productDetails}
            update={update}
            setViewAddress={setViewAddress}
          />
        ) : (
          <OrderTimeline
            stages={product?.stages || []}
            image={product?.image}
          />
        )}
      </CustomDialog>
    </div>
  );
}

export default ProductCard;
