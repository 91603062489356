// import { productDetails } from "../mocks/productDetailsMock";
import { commonGet } from "./common";
import { endpoints } from "./apis";

export const getAllProducts = async () => {
  try {
    return await commonGet(endpoints.getAllProduct);
  } catch (e) {
    console.log("something went wrong!!", e);
    return [];
  }
};

// export const getProductDetails = (id) => {
//   return new Promise((res, rej) => {
//     res(productDetails);
//   });
// };
