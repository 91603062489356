import * as React from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function CustomDialog({ title = "", children, open, setOpen }) {
  const handleClose = (e) => {
    console.log("close::", e);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby={`alert-dialog-${title}`}
      aria-describedby="alert-dialog-description"
    >
      {title && (
        <DialogTitle id={`alert-dialog-${title}`}>
          <div className="a-row">
            <div className="title">{title}</div>
            <div className="close" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </div>
          </div>
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
