import React from "react";

const Privacy = () => {
  return (
    <div className="pad-10">
      <h2>Privacy Policy</h2>
      <div className="title">Introduction</div>
      <p>
        ayraasfashion respects the privacy of its users. This Privacy Policy
        explains how we collect, use, disclose, and safeguard your information
        when you visit our website{" "}
        <a href="ayraasfashion.com">ayraasfashion.com</a>. Please read this
        Privacy Policy carefully. If you do not agree with the terms of this
        Privacy Policy, please do not access the Site.
      </p>
      <div className="title">Information We Collect</div>
      <p>We collect the following types of information from you:</p>
      <ul>
        <li>
          Personal Information: This includes information that can be used to
          identify you, such as your name, email address, shipping address, and
          billing address.
        </li>
        <li>
          Contact Information: This includes information that you provide to us
          when you contact us, such as your name, email address, and phone
          number.
        </li>
        <li>
          Transactional Information: This includes information about your
          purchases from us, such as your order number, product information, and
          shipping information.
        </li>
        <li>
          Technical Information: This includes information about your computer
          and your internet connection, such as your IP address, browser type,
          and operating system.
        </li>
        {/* <li>
          Tracking Information: This includes information about your activities
          on our Site, such as the pages you visit, the products you view, and
          the links you click.
        </li> */}
      </ul>
      <div className="title">How We Use Your Information</div>
      <p>We use your information for the following purposes:</p>
      <ul>
        <li>
          To process your orders and fulfill your requests. We use your personal
          information to process your orders, fulfill your requests, and provide
          you with customer support.
        </li>
        <li>
          To improve our Site and services. We use your information to improve
          our Site and services, such as by personalizing your experience,
          analyzing traffic patterns, and debugging our software.
        </li>
        <li>
          To send you marketing communications. We may use your contact
          information to send you marketing communications about our products
          and services. You may opt out of receiving marketing communications at
          any time by clicking on the "unsubscribe" link in any marketing email.
        </li>
        <li>
          To comply with laws and regulations. We may use your information to
          comply with laws and regulations, such as those related to fraud
          prevention and product safety.
        </li>
      </ul>
      <div className="title">How We Share Your Information</div>
      <p>We may share your information with the following third parties:</p>
      <ul>
        <li>
          Our service providers: We may share your information with our service
          providers, such as those who process payments, deliver orders, or
          provide customer support.
        </li>
        <li>
          Business partners: We may share your information with our business
          partners, such as those who promote our products or services.
        </li>
        <li>
          Legal authorities: We may share your information with legal
          authorities if we are required to do so by law or if we believe that
          sharing is necessary to protect our rights or the rights of others.
        </li>
      </ul>
      <div className="title">How We Protect Your Information</div>
      <p>
        We take the security of your information seriously. We use a variety of
        security measures to protect your information, such as firewalls,
        encryption, and physical security measures.
      </p>
      <div className="title">Your Rights</div>
      <p>You have the following rights with respect to your information:</p>
      <ul>
        <li>
          Access: You have the right to access your information. You can request
          a copy of your information by contacting us.
        </li>
        <li>
          Correction: You have the right to correct any inaccuracies in your
          information. You can request a correction of your information by
          contacting us.
        </li>
        <li>
          Deletion: You have the right to delete your information. You can
          request the deletion of your information by contacting us.
        </li>
        <li>
          Opt-out: You have the right to opt out of receiving marketing
          communications. You can opt out of receiving marketing communications
          by clicking on the "unsubscribe" link in any marketing email.
        </li>
      </ul>
      <div className="title">How to Contact Us</div>
      <p>
        If you have any questions about this Privacy Policy, please contact us:
      </p>
      <div>AyraasFashion</div>
      <div>+919745309722</div>
      <div>support@ayraasfashion.com</div>
      <div className="title">Effective Date</div>
      <p>
        This Privacy Policy is effective as of 01/08/2023. We may update this
        Privacy Policy from time to time. If we make any material changes to
        this Privacy Policy, we will notify you by email or by posting a notice
        on our Site.
      </p>
    </div>
  );
};

export default Privacy;
