import React, { useEffect } from "react";
import "./ImageFrame.css";

export const ImageFrame = ({ img, frame }) => {
  useEffect(() => {
    const objects = document.getElementsByClassName("asyncImage");
    Array.from(objects).map((item) => {
      const img = new Image();
      img.src = item.dataset.src;
      img.onload = () => {
        item.classList.remove("asyncImage");
        return item.nodeName === "IMG"
          ? (item.src = item.dataset.src)
          : (item.style.backgroundImage = `url("${item.dataset.src}")`);
      };
    });
  }, []);
  const style = {
    backgroundColor: "#f8f8f8",
    backgroundImage: `url("/thumb.jpg")`,
    backgroundPosition: "center",
    backgroundAttachment: "local",
    backgroundRepeat: "no-repeat",
    backgroundBlendMode: "multiply",
    backgroundSize: "contain",
  };
  return (
    <div className="frameWrapper asyncImage" data-src={img} style={style}></div>
  );
};
