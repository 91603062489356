import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { endpoints } from "../../../services/apis";
import { useApiCall } from "../../../hooks/useApiCall";
import { ProductContext } from "../../../Contexts/ProductContext";

function CategoryCard({ category }) {
  const navigate = useNavigate();
  const viewByCategory = () => {
    navigate(`/categories/${category.label}`);
  };
  return (
    <div onClick={viewByCategory} className="categoryCard card-shadow pointer">
      <img src={category.img} alt={category.label} />
      <div className="center">{category.label}</div>
    </div>
  );
}

export default CategoryCard;
