import React, { useState, useEffect } from "react";
import { Button, Grid, TextField, Autocomplete } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CategoryMetaData, InputWidth } from "../../../helpers/constants";
import { commonGet, commonPost } from "../../../services/common";
import { endpoints } from "../../../services/apis";

function CreateCategory() {
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);

  const createCategory = async () => {
    const obj = {
      label: category,
      parent: selectedCategory?.label || "No parent",
    };
    await commonPost(endpoints.createCategory, obj);
    getCategories();
    setCategory("");
    setSelectedCategory(null);
  };
  const getCategories = async () => {
    const result = await commonGet(endpoints.getCategories);
    console.log("categories::", result[1]);
    const data = result[1].map((d, idx) => ({ ...d, id: idx }));
    setCategories(data);
  };
  useEffect(() => {
    getCategories();
  }, []);
  const handleSelectCategory = (e, value) => {
    console.log("handleSelectCategory::", e, value);
    setSelectedCategory(value);
  };
  const handleCategoryNameChange = (e) => {
    const { value } = e.target;
    setCategory(value.trim());
  };
  return (
    <div className="dash-right">
      <Grid container spacing={2}>
        <Grid item xs={12} xl={6}>
          <div className="input-item">
            <Autocomplete
              disablePortal
              id="combo-box-categories"
              options={categories}
              sx={{ width: InputWidth }}
              value={selectedCategory}
              onChange={handleSelectCategory}
              renderInput={(params) => (
                <TextField {...params} label="Categories" />
              )}
            />
          </div>
          <div className="input-item">
            <TextField
              onChange={handleCategoryNameChange}
              sx={{ width: InputWidth }}
              // style={{ width: "100%" }}
              value={category}
              id="category-name-id"
              label="CategoryName"
              variant="outlined"
            />
          </div>
          <div className="input-item flex-center">
            <Button
              variant="contained"
              component="label"
              onClick={createCategory}
            >
              CREATE
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} xl={6}>
          {categories && categories.length > 0 && (
            <div style={{ height: InputWidth, width: "100%" }}>
              <DataGrid
                rows={categories}
                columns={CategoryMetaData}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
              />
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default CreateCategory;
