import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button } from "@mui/material";
import "./LoginCard.css";
import { verifyOtp } from "../../../services/authenticationService";
import { ProductContext } from "../../../Contexts/ProductContext";

function VerifyPhone({ changePage }) {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const { getUser } = useContext(ProductContext);

  const handleVerify = async () => {
    const phone = localStorage.getItem("phone");
    if (!phone) {
      navigate("/register");
      changePage(2);
    }
    const resp = await verifyOtp({ phone, otp });
    if (resp) {
      getUser();
      navigate("/register/change-password");
      changePage(4);
    } else alert("OTP is not valid or expired one");
  };

  return (
    <div className="loginWrapper card-shadow">
      <div className="title">
        <h2>Verify Phone</h2>
      </div>
      <div className="input">
        <TextField
          required
          fullWidth
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          type="number"
          id="outlined-required"
          placeholder="Enter the OTP"
        />
      </div>
      <div className="btn center width-100">
        <Button onClick={handleVerify} variant="contained" color="success">
          Verify
        </Button>
      </div>
    </div>
  );
}

export default VerifyPhone;
