import React from "react";
import "./Input.css";
import { FaSearch } from "react-icons/fa";

export const Input = ({ placeholder, click, value, setValue }) => {
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      click();
    }
  };
  return (
    <div className="input-primary">
      <input
        value={value}
        onKeyDown={handleKeyDown}
        onChange={(e) => setValue(e.target.value)}
        type="text"
        placeholder={placeholder}
      />
      <span className="icon-search">
        <FaSearch onClick={click ? click : null} />
      </span>
    </div>
  );
};
